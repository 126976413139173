





















import Vue from 'vue';
import BaseMenu from '@/components/BaseMenu.vue';

export default Vue.extend({
  components: {
    BaseMenu
  },
  props: {
    label: {
      type: String,
      default: 'Dropdown Label'
    },
    isCollapsed: {
      type: Boolean,
      default: false
    }
  }
});
