






















































import Vue from 'vue';
import BaseBtn from '@/components/BaseBtn.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import PipelineSiteNoteApi from '@/api/PipelineSiteNoteApi';
import { Pipeline } from '@/types/Pipeline';
import _ from 'lodash';
import BaseTextArea from '@/components/BaseTextArea.vue';

type VueExt = Vue & {
  $refs: {
    textNote: {
      $el: {
        input: InstanceType<typeof HTMLInputElement>;
      };
    };
  };
};

export default Vue.extend({
  components: {
    BaseBtn,
    BaseTextArea,
    BaseMenu
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    siteId: {
      type: Number,
      required: true
    }
  },
  computed: {
    hasNote(): boolean {
      return !_.isEmpty(this.value);
    },
    displayedNoteSnippet(): string {
      if (!this.hasNote) {
        return 'Add note';
      }
      return this.value.substring(0, 16);
    },
    activePipeline(): Pipeline | null {
      return this.$store.direct.state.Pipeline.activePipeline;
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.note = this.value;
        this.focusTextArea();
        document.addEventListener('wheel', this.wheelHandler);
      } else {
        document.removeEventListener('wheel', this.wheelHandler);
        this.updateNote();
      }
    }
  },
  data: () => ({
    note: '' as string,
    isOpen: false as boolean,
    isSavingNote: false as boolean,
    isTextAreaFocused: false as boolean
  }),
  methods: {
    wheelHandler() {
      if (this.isTextAreaFocused) {
        return;
      }
      this.isOpen = false;
    },
    enterHandler(e) {
      if (e.shiftKey) return;
      this.isOpen = false;
      this.updateNote();
    },
    async updateNote() {
      if (this.note === this.value) {
        return;
      }
      if (!this.activePipeline) {
        return;
      }
      this.isSavingNote = true;
      try {
        await PipelineSiteNoteApi.store(this.activePipeline.id, this.siteId, this.note);
        this.$emit('input', this.note);
      } catch (e) {
        console.error(e);
      } finally {
        this.isSavingNote = false;
      }
    },
    clearNote() {
      this.note = '';
      this.isOpen = false;
      this.focusTextArea();
    },
    focusTextArea() {
      // Timeout workaround required for programatically focusing Vuetify textarea
      this.$nextTick(() => {
        setTimeout(() => {
          const $textNoteEl = (this.$refs.textNote as Vue).$el;
          const input = $textNoteEl.querySelector('textarea');
          if (input) {
            input.focus();
          }
        }, 300);
      });
    }
  }
});
