









import Vue from 'vue';

export default Vue.extend({
  props: {
    variant: {
      type: String,
      default: 'success'
    },
    message: {
      type: String,
      default: 'Success!',
      required: false
    }
  },
  computed: {
    icon(): string | null {
      switch (this.variant) {
        case 'success':
          return 'check-circle';
        default:
          return null;
      }
    }
  }
});
