






















































































































import Vue from 'vue';
import Map from '@/services/map';
import BaseAutocomplete from '@/components/BaseAutocomplete.vue';
import _ from 'lodash';
import GnafLotIdSearchApi from '@/api/GnafLotIdSearchApi';
import { LngLatLike } from 'mapbox-gl';
import MapPropertyIdApi from '@/api/MapPropertyIdApi';
import BaseBtn from '@/components/BaseBtn.vue';
// import SiteDetailsApi from '@/api/SiteDetailsApi';
import PipelineAddSites from '@/api/PipelineAddSites';
import { Pipeline } from '@/types/Pipeline';
import { Site } from '@/types/Site';

export interface ListSingleItem {
  text?: string | number | object;
  value?: string | number | object | LngLatLike;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
  group?: string;
}

export default Vue.extend({
  components: { BaseBtn, BaseAutocomplete },
  data: () => ({
    singleSearchText: '' as string,
    resultsGeocode: [] as string[],
    resultsLotIdSearch: [] as string[],
    resultCoordinateSearch: null as LngLatLike | null,
    isLoading: false as boolean,
    selectedAddress: [] as any,
    addressText: '' as string,
    siteAddresses: [] as string[],
    showAddingSitesWarning: false as boolean,
    notFoundSite: '' as string
  }),

  computed: {
    singleItems(): ListSingleItem[] {
      // Build singleItems array to feed into autocomplete
      const singleItems: ListSingleItem[] = [];
      if (!_.isEmpty(this.resultsGeocode)) {
        singleItems.push({ divider: true });
        singleItems.push({ header: 'Addresses' });
      }
      singleItems.push(
        ..._.map(this.resultsGeocode, result => {
          return {
            text: result.place_name,
            value: result,
            group: 'Addresses'
          };
        })
      );

      if (!_.isEmpty(this.resultsLotIdSearch)) {
        singleItems.push({ divider: true });
        singleItems.push({ header: 'Lots' });
      }
      singleItems.push(
        ..._.map(this.resultsLotIdSearch, result => {
          return {
            text: this.getLotIdSuggestionDisplay(result),
            value: result,
            group: 'Lots'
          };
        })
      );

      if (this.resultCoordinateSearch) {
        singleItems.push({ divider: true });
        singleItems.push({ header: 'Coordinate' });
        singleItems.push({
          text: `${this.resultCoordinateSearch[0]}, ${this.resultCoordinateSearch[1]}`,
          value: this.resultCoordinateSearch,
          group: 'Coordinate'
        });
      }

      return singleItems;
    },
    enableAddSitesButton(): boolean {
      return this.selectedAddress.length > 0 && !this.isLoading;
    },
    activePipeline(): Pipeline | null {
      return this.$store.direct.state.Pipeline.activePipeline;
    },
    isFirstStageActive(): boolean {
      return this.$store.direct.getters.Pipeline.isFirstStageActive;
    },
    activeStageId(): number | null {
      return this.$store.direct.state.Pipeline.activeStageId;
    }
  },
  watch: {
    singleSearchText(val) {
      if (_.isEmpty(val)) {
        this.resetItems();
        return;
      }
      this.querySearch();
    }
  },
  methods: {
    removeSite(siteInx) {
      this.selectedAddress.splice(siteInx, 1);
    },
    setActiveSiteGuid(siteGuid: string, point: LngLatLike) {
      return this.$store.direct.dispatch.Site.setActiveSiteGuid({ siteGuid, point });
    },
    selectSingleSite(siteGuid: string) {
      return this.$store.direct.dispatch.Site.selectSingleSite(siteGuid);
    },
    appendActiveSiteGuid(siteGuid: string) {
      return this.$store.direct.dispatch.Site.appendActiveSiteGuid(siteGuid);
    },
    querySearch() {
      this.geocoderSearch(this.singleSearchText);
      this.gnafLotIdSearch(this.singleSearchText);
      this.coordinateSearch(this.singleSearchText);
    },
    resetItems() {
      this.resultsGeocode = [];
    },
    async geocoderSearch(query) {
      let geocodeCountry = '';
      if (this.$store.direct.state.User.appSettings?.geocodingCountry === 'undefined') {
        geocodeCountry = 'au';
      } else if (this.$store.direct.state.User.appSettings?.geocodingCountry) {
        geocodeCountry = this.$store.direct.state.User.appSettings?.geocodingCountry;
      }
      this.resultsGeocode = await Map.geocode(query, geocodeCountry);
    },
    async gnafLotIdSearch(query) {
      const response = await GnafLotIdSearchApi.store(query);
      //Ignore any results under 7 as they are not usually relevant
      this.resultsLotIdSearch = response.data.filter(result => {
        return result._score >= 7;
      });
    },
    closeWarning() {
      setTimeout(() => (this.showAddingSitesWarning = false), 3000);
    },
    coordinateSearch(query) {
      const coordRegexp = /^(^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))$/g;
      const match = coordRegexp.exec(query);
      if (!match || match.length !== 3) {
        this.resultCoordinateSearch = null;
        return;
      }
      this.resultCoordinateSearch = [parseFloat(match[1]), parseFloat(match[2])];
    },
    getLotIdSuggestionDisplay(result) {
      const displayString = `${result._source.street_address}, ${result._source.locality_name} ${result._source.state_abbreviation} ${result._source.postcode}`;
      return `${result._source.legal_parcel_id} - ${_.startCase(_.toLower(displayString))}`;
    },
    selected(result) {
      if (result.group === 'Addresses') {
        const isAddressType = result.value.place_type.includes('address');
        this.addressText = result.text;
        const coord: LngLatLike = [result.value.center[0], result.value.center[1]];
        this.showSelectedSite(coord, isAddressType);
      }
      if (result.group === 'Lots') {
        const coord: LngLatLike = [result.value._source.longitude, result.value._source.latitude];
        this.showSelectedSite(coord);
      }
      if (result.group === 'Coordinate') {
        const coord: LngLatLike = [result.value[1], result.value[0]];
        this.showSelectedSite(coord);
      }
    },
    // async fetchSiteAddressFromApi(siteGuid: string) {
    //   await SiteDetailsApi.show(siteGuid);
    // },
    async showSelectedSite(coord: LngLatLike, highlightSite = true) {
      if (highlightSite) {
        const response = await MapPropertyIdApi.store(coord);
        const siteGuid = response.data.data;
        if (_.isEmpty(siteGuid) || siteGuid === undefined) {
          this.showAddingSitesWarning = true;
          this.notFoundSite = this.addressText;
          this.singleSearchText = '';
          this.closeWarning();
          return;
        }

        this.siteAddresses.push(siteGuid[0]);
        this.selectedAddress.push({
          siteGuid: siteGuid[0],
          label: this.addressText
        });
        this.singleSearchText = '';
        this.resetItems();
        // this.singleItems = [];
      }
    },

    addSites(sites: Site[]) {
      if (this.isFirstStageActive) {
        this.$store.direct.dispatch.Site.addSites(sites);
        return;
      }
      this.$store.direct.dispatch.Pipeline.addConfigDetailStageSiteCount({
        stageId: this.activeStageId,
        count: sites.length
      });
    },

    async addSitesToPipeline() {
      if (!this.activePipeline) return;
      try {
        this.isLoading = true;
        const response = await PipelineAddSites.store(this.activePipeline.id, this.siteAddresses);
        this.addSites(response?.data.data.sites);
        this.selectedAddress = [];
        this.singleSearchText = '';
        this.$emit('singleSiteAdded');
      } finally {
        this.isLoading = false;
      }
    }
  }
});
