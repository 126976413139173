
























import Vue from 'vue';

export default Vue.extend({
  methods: {
    cancel() {
      this.$emit('input', false);
    }
  }
});
