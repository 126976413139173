/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { AuthUser, Helper } from '@archistarai/auth-frontend/src';

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function(location): any {
  return originalPush.call(this, location);
};

Vue.use(VueRouter);

/**
 * Automatically imports all the modules and exports as a single module object
 */
const requireModule = require.context('.', false, /\.routes\.ts$/);

// @ts-ignore
const routes: Array<RouteConfig> = requireModule.keys().reduce((prev, filename) => {
  const data = requireModule(filename).default || requireModule(filename);
  return [...prev, ...data];
}, [] as Array<RouteConfig>);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    ...Helper.GetAuthRoutes(),
    {
      path: '*',
      redirect: '/'
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  await Helper.RouterBeforeEach(to, from);

  // If the resource name is present in meta config, check for read access
  const canReadResource = to?.meta?.resource ? AuthUser.canReadResource(to.meta.resource) : true;

  // const workspaceAccess = AuthUser.hasAccessToResource('workspace');

  // next();
  if (to.path.includes('error') || canReadResource) {
    next();
  } else {
    next({ path: '/error/403' });
  }
});

export default router;
