import { APIHelper } from '@archistarai/auth-frontend';
import { Suburb } from '@/types/Suburb';

export default {
  async post(suburb: string, selectedSub: Suburb) {
    return APIHelper.ManageAPI.post('filters/sites/suburb-list', {
      suburb: suburb,
      scope: 'localities',
      namesOnly: true,
      selectedSuburb: selectedSub
    });
  }
};
