















import Vue from 'vue';
import Loader from '@/components/Common/Loader.vue';
import { AppSettings } from '@/types/AppSettings';
import GlobalSidenav from '@/components/GlobalSidenav.vue';
import BaseDialogConfirm from '@/components/BaseDialogConfirm.vue';
import { AuthUser, Analytics } from '@archistarai/auth-frontend';

type dialogConfirm = Vue & {
  open: (title: string, message: string, buttonText: string) => Promise<boolean>;
};

export default Vue.extend({
  name: 'App',
  components: {
    BaseDialogConfirm,
    GlobalSidenav,
    Loader
  },
  data: () => ({
    loading: true as boolean
  }),
  mounted() {
    const dialogConfirm = this.$refs.dialogConfirm as dialogConfirm;
    this.$root.$confirm = dialogConfirm.open;
  },
  watch: {
    $authUser: {
      deep: true,
      handler(value: typeof AuthUser) {
        if (value?.guid) {
          this.initUser(value);
        } else {
          //No user logged in - stuck in loader
          this.loading = true;
        }
      },
      immediate: true
    }
  },
  methods: {
    initUser(newUser: typeof AuthUser): void {
      if (process.env.VUE_APP_ENV === 'production') {
        Analytics.property.identify(newUser);
      }

      if (this.$APIHelper) {
        this.$APIHelper.ArchistarAPI.get('map/getappsettings').then(response => {
          if (response && response.data) {
            const appSettings = response.data as AppSettings;
            this.$store.direct.dispatch.User.updateAppSettings(appSettings);

            if (appSettings.refreshAuth) {
              location.reload();
            }

            this.$nextTick(() => {
              //Unlock interface
              this.loading = false;
              this.$store.direct.dispatch.User.setUserLoaded();
            });
          }
        });
      }
    }
  }
});
