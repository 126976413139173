








import Vue from 'vue';
import SiteList from '@/components/SiteList.vue';

export default Vue.extend({
  components: { SiteList }
});
