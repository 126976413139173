import { APIHelper } from '@archistarai/auth-frontend';
import { Suburb } from '@/types/Suburb';
import { Zone } from '@/types/Zone';

export default {
  async post(suburb: Suburb[], zone: string, selectedZone: Zone[]) {
    return APIHelper.ManageAPI.post('filters/sites/zone-list', {
      suburb: suburb,
      zone: zone,
      selectedZone: selectedZone
    });
  }
};
