import { APIHelper } from '@archistarai/auth-frontend';

export default {
  async store(query: string) {
    return APIHelper.GnafSearchAPI.post('', {
      query: {
        bool: {
          must: [
            {
              match: {
                legal_parcel_id: {
                  query
                }
              }
            }
          ]
        }
      }
    });
  }
};
