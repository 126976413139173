
































































































import Vue from 'vue';
import BaseDialog from '@/components/BaseDialog.vue';
import BaseBtn from '@/components/BaseBtn.vue';
import { Pipeline } from '@/types/Pipeline';
import { PipelineConfigAssessment } from '@/types/PipelineConfigAssessment';
import AssessmentsConfigApi from '@/api/AssessmentsConfigApi';
import Loader from '@/components/Common/Loader.vue';
import BaseTextField from '@/components/BaseTextField.vue';

export default Vue.extend({
  components: { BaseDialog, BaseBtn, Loader, BaseTextField },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    isCreating: false as boolean,
    isBusy: false as boolean,
    assessments: {} as PipelineConfigAssessment
  }),
  computed: {
    isShownDialog: {
      get(): boolean {
        return this.value;
      },
      set(isShownDialog: boolean) {
        this.$emit('input', isShownDialog);
      }
    },
    pipeline(): Pipeline | null {
      return this.$store.direct.state.Pipeline.activePipeline;
    }
  },
  async mounted() {
    await this.getAssessments();
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    async getAssessments() {
      if (this.pipeline) {
        const response = await AssessmentsConfigApi.show(this.pipeline.id);
        this.assessments = response?.data.data;
      }
    },
    async updateAssessments() {
      if (this.pipeline) {
        this.isBusy = true;
        const response = await AssessmentsConfigApi.update(this.pipeline.id, this.assessments);
        this.pipeline.configDetails.assessments = response.data.data;
        //set the pipeline  in the store
        this.$store.direct.dispatch.Pipeline.setActivePipeline(this.pipeline);
        //get the site for the stage
        this.$emit('siteAdded');
        this.$nextTick(() => {
          this.isBusy = false;
          this.$emit('close');
        });
      }
    }
  }
});
