import { APIHelper } from '@archistarai/auth-frontend';

export default {
  async setInfoLayer(siteId: number, layer: string) {
    return APIHelper.ManageAPI.post(`site-info-image/${siteId}`, { type: 'layer', name: layer, legend: true });
  },
  async setInfoImage(siteId: number, image: string) {
    return APIHelper.ManageAPI.post(`site-info-image/${siteId}`, { type: 'image', name: image });
  },
  async getSite(siteId: number) {
    return APIHelper.ManageAPI.get(`site/${siteId}`);
  }
};
