











import _ from 'lodash';
import Vue from 'vue';
import Map from '@/services/map';
import SiteHelpers from '@/services/siteHelpers';
import { point, featureCollection } from '@turf/turf';
import { Feature } from 'geojson';
import { FilterLayerStatus } from '@/types/FilterLayerStatus';
import { FilterLayer } from '@/types/FilterLayer';
import AssessmentLayerFilterMap from '@/config/assessmentLayerFilterMap';
import { GeoJSONSource, LngLatLike } from 'mapbox-gl';
import * as turf from '@turf/turf';
import PopupAddSite from '@/components/PopupAddSite.vue';
import PopupAssessSite from '@/components/PopupAssessSite.vue';
import { Site } from '@/types/Site';

const SITES_LAYER = Object.freeze({
  SOURCE: 'SITES_SOURCE',
  NAME: 'SITES_LAYER'
});
const CLUSTER_MAX_ZOOM = 14;

export default Vue.extend({
  data() {
    return {
      lastSiteGuidActivatedByClick: null as string | null
    };
  },
  computed: {
    siteGuids(): readonly string[] {
      return _.clone(this.$store.direct.getters.Site.siteGuids);
    },
    sites(): readonly Site[] {
      return _.clone(this.$store.direct.state.Site.sites);
    },
    greenSiteIds(): string[] {
      if (!this.activeAssessmentId) {
        return [];
      }
      const greenSites = _.filter(this.sites, site => {
        return SiteHelpers.getSiteAssessmentValue(site, this.activeAssessmentId as number) === 'pass';
      });
      return _.map(greenSites, 'details.guid');
    },
    redSiteIds(): string[] {
      if (!this.activeAssessmentId) {
        return [];
      }
      const greenSites = _.filter(this.sites, site => {
        return SiteHelpers.getSiteAssessmentValue(site, this.activeAssessmentId as number) === 'fail';
      });
      return _.map(greenSites, 'details.guid');
    },
    activeSiteGuids(): string[] {
      return this.$store.direct.state.Site.activeSiteGuids;
    },
    activeAssessmentId(): number | null {
      return this.$store.direct.state.Pipeline.activeAssessmentId;
    },
    activeStageId(): number | null {
      return this.$store.direct.state.Pipeline.activeStageId;
    },
    filterStatuses(): FilterLayerStatus {
      return this.$store.direct.state.FilterLayer.filterStatuses;
    },
    filterLayers(): FilterLayer[] {
      return this.$store.direct.state.FilterLayer.filterLayers;
    }
  },
  watch: {
    siteGuids: {
      handler(newValue, oldValue) {
        if (_.size(newValue) !== _.size(oldValue)) {
          this.updateFilteredSites();
        }
        if (_.size(oldValue) === 0 && !_.isEmpty(newValue)) {
          this.flyToSite(this.sites[Object.keys(this.sites)[0]].details.guid);
        }
      }
    },
    sites: {
      deep: true,
      handler() {
        this.updateFilteredLayerPaint();
        this.updateSitesGeojsonSource();
      }
    },
    activeSiteGuids: {
      handler(value: string[]) {
        if (_.isEmpty(value) || value.length > 1) {
          Map.clearAllPopups();
        } else {
          const siteGuid = value[0];
          if (value[0] !== this.lastSiteGuidActivatedByClick) {
            this.flyToSite(siteGuid, 18);
          }
          if (this.$store.direct.state.Site.firstActiveSitePoint) {
            this.showPopup(this.$store.direct.state.Site.firstActiveSitePoint, siteGuid);
          }
        }

        this.updateFilteredLayerPaint();
        this.updateHighlightedSites();
        if (this.filterStatuses['Nearmap']) {
          // If nearmap is active, don't filter (eg: show shade) on active sites
          this.updateFilteredSites();
        }
      }
    },
    activeAssessmentId: {
      handler() {
        this.adjustFilterLayerState();
        this.updateFilteredLayerPaint();
      }
    },
    activeStageId: {
      handler() {
        this.clearActiveSites();
        this.clearSelectedSites();
      }
    },
    filterStatuses: {
      deep: true,
      handler() {
        this.updateMapFilterLayers();
        this.updateHighlightLotsOutlineLayerColor();
        this.updateHighlightedSites();
        this.updateFilteredSites();
      }
    }
  },
  async mounted() {
    Map.initialise(
      this.$store.direct.state.User.appSettings?.centerLat || -33.86785,
      this.$store.direct.state.User.appSettings?.centerLong || 151.20732
    );

    Map.bindClick('Lots', event => {
      if (Map.map.getZoom() <= CLUSTER_MAX_ZOOM + 1) {
        return;
      }
      if (event.features === undefined) {
        return;
      }
      if (event.features.length === 0) {
        this.clearActiveSites();
        this.clearSelectedSites();
        return;
      }

      const siteGuid = event.features[0].properties?.guid;

      if (event.originalEvent.shiftKey && !_.isEmpty(this.activeSiteGuids)) {
        // Multiselect
        if (!this.isSiteActive(siteGuid)) {
          // Select
          this.setLastSiteActivatedByClick(siteGuid);
          this.appendActiveSiteGuid(siteGuid);
          this.selectSite(siteGuid);
        } else {
          // De-select
          this.unsetLastSiteActivatedByClick();
          this.removeActiveSiteGuid(siteGuid);
          this.unselectSite(siteGuid);
        }
        return;
      }

      // Single-Select
      let point: LngLatLike | null = null;
      if (!this.$store.direct.getters.Site.hasSite(siteGuid)) {
        point = turf.centerOfMass(event.features[0].geometry).geometry.coordinates as LngLatLike;
      }
      this.setLastSiteActivatedByClick(siteGuid);
      this.setActiveSiteGuid(siteGuid, point);
      this.selectSingleSite(siteGuid);
    });

    Map.bindClick(null, event => {
      // Don't deselect if shift-key held
      if (event.originalEvent.shiftKey) {
        return;
      }
      // Deselect highlighted lot on click outside of the lot layer
      const features = Map.map.queryRenderedFeatures(event.point, { layers: ['Lots', 'lga'] });
      if (
        _.find(features, feature => {
          return feature.layer.id === 'Lots';
        }) === undefined
      ) {
        this.unsetLastSiteActivatedByClick();
        this.clearActiveSites();
        this.clearSelectedSites();
      }
    });

    await Map.waitForMapReady();
    this.addSitesLayer();
    this.updateSitesGeojsonSource();
    Map.map.setPaintProperty('HighlightLots', 'fill-color', '#E6ECFA');
    Map.map.setPaintProperty('FilteredLots', 'fill-opacity', 0.8);
    this.updateHighlightLotsOutlineLayerColor();
    this.updateFilteredSites();
    Map.map.moveLayer('Nearmap', 'FilteredLots');
  },
  methods: {
    addSitesLayer(): void {
      Map.map.addSource(SITES_LAYER.SOURCE, {
        type: 'geojson',
        data: featureCollection([]),
        cluster: true,
        clusterMaxZoom: CLUSTER_MAX_ZOOM, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      });

      Map.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: SITES_LAYER.SOURCE,
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': ['step', ['get', 'point_count'], '#5863F8', 100, '#5863F8', 750, '#5863F8'],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
        }
      });

      Map.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: SITES_LAYER.SOURCE,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        paint: {
          'text-color': '#ffffff'
        }
      });

      Map.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: SITES_LAYER.SOURCE,
        filter: ['!', ['has', 'point_count']],
        maxzoom: 18,
        paint: {
          'circle-color': '#5863F8',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#ffffff'
        }
      });

      // inspect a cluster on click
      Map.map.on('click', 'clusters', function(e) {
        const features = Map.map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0]?.properties?.cluster_id;
        if (!clusterId) {
          return;
        }
        (Map.map.getSource(SITES_LAYER.SOURCE) as GeoJSONSource).getClusterExpansionZoom(clusterId, function(
          err,
          zoom
        ) {
          if (err) return;

          Map.map.easeTo({
            center: (features[0].geometry as any).coordinates,
            zoom: zoom
          });
        });
      });

      Map.map.on('mouseenter', 'clusters', function() {
        Map.map.getCanvas().style.cursor = 'pointer';
      });
      Map.map.on('mouseleave', 'clusters', function() {
        Map.map.getCanvas().style.cursor = '';
      });
    },
    isSiteActive(siteGuid: string): boolean {
      return this.$store.direct.getters.Site.isSiteActive(siteGuid);
    },
    appendActiveSiteGuid(siteGuid: string) {
      return this.$store.direct.dispatch.Site.appendActiveSiteGuid(siteGuid);
    },
    removeActiveSiteGuid(siteGuid: string) {
      return this.$store.direct.dispatch.Site.removeActiveSiteGuid(siteGuid);
    },
    setActiveSiteGuid(siteGuid: string, point: LngLatLike | null = null) {
      return this.$store.direct.dispatch.Site.setActiveSiteGuid({ siteGuid, point });
    },
    clearActiveSites() {
      return this.$store.direct.dispatch.Site.clearActiveSites();
    },
    selectSite(siteGuid: string) {
      return this.$store.direct.dispatch.Site.selectSite(siteGuid);
    },
    unselectSite(siteGuid: string) {
      return this.$store.direct.dispatch.Site.unselectSite(siteGuid);
    },
    selectSingleSite(siteGuid: string) {
      return this.$store.direct.dispatch.Site.selectSingleSite(siteGuid);
    },
    clearSelectedSites() {
      return this.$store.direct.dispatch.Site.clearSelectedSites();
    },
    updateFilteredLayerPaint() {
      if (!Map.isMapReady()) {
        return;
      }
      Map.map.setPaintProperty('FilteredLots', 'fill-color', [
        'case',
        ['in', ['get', 'guid'], ['literal', this.greenSiteIds]],
        '#289F7C',
        ['in', ['get', 'guid'], ['literal', this.redSiteIds]],
        '#F05A5B',
        '#EAEEF0'
      ]);
    },
    updateSitesGeojsonSource() {
      const features: Feature[] = _.map(this.sites, site => {
        return point([site.details.point.longitude, site.details.point.latitude]);
      });
      const source = Map.map.getSource(SITES_LAYER.SOURCE) as mapboxgl.GeoJSONSource;
      if (source) {
        source.setData(featureCollection(features));
      }
    },
    flyToSite(siteGuid: string, zoom = 16) {
      const site = this.$store.direct.getters.Site.getSite(siteGuid);
      if (site !== undefined) {
        Map.flyToPoint([site.details.point.longitude, site.details.point.latitude], zoom);
      }
    },
    updateMapFilterLayers() {
      Object.keys(this.filterLayers).forEach(idxCat => {
        this.filterLayers[idxCat].filters.forEach(itGroup => {
          const status = this.filterStatuses[itGroup.name];
          itGroup.layers.forEach(itLyr => {
            Map.toggleArchistarLayer(itLyr, status ?? false);
          });
        });
      });
    },
    async updateHighlightLotsOutlineLayerColor() {
      await Map.waitForMapReady();
      if (this.filterStatuses['Nearmap']) {
        Map.map.setPaintProperty('HighlightLotsOutline', 'line-color', '#FF1493');
        return;
      }
      Map.map.setPaintProperty('HighlightLotsOutline', 'line-color', '#5863F8');
    },
    adjustFilterLayerState() {
      this.$store.direct.commit.FilterLayer.RESET_FILTER_LAYERS_TO_DEFAULT();
      const assName = this.$store.direct.getters.Pipeline.getActiveAssessment?.name;
      if (!assName) return;
      if (!AssessmentLayerFilterMap[assName]) return;
      AssessmentLayerFilterMap[assName].filterLayers.forEach(x => {
        this.$store.direct.commit.FilterLayer.TOGGLE_FILTER_LAYER_STATUS({ filterLayerName: x, status: true });
      });
    },
    showPopup(point: LngLatLike, siteGuid: string) {
      const popup = Map.addPopUp(point as LngLatLike, {
        closeButton: false,
        offset: 15,
        anchor: 'left',
        maxWidth: '260px'
      }).setHTML('<div id="popupAddSite"></div>');
      let PopupComponent;
      if (this.sites[siteGuid] === undefined) {
        PopupComponent = Vue.extend(PopupAddSite);
      } else {
        PopupComponent = Vue.extend(PopupAssessSite);
      }
      const sitePopup = new PopupComponent({
        propsData: {
          siteGuid: siteGuid
        },
        store: this.$store
      });
      sitePopup.$mount('#popupAddSite');
      sitePopup.$on('site-added', () => popup.remove());
      sitePopup.$on('close', () => popup.remove());
    },
    updateHighlightedSites() {
      if (_.isEmpty(this.activeSiteGuids)) {
        Map.highlightLots();
        Map.highlightLotsOutline();
        return;
      }
      if (this.filterStatuses['Nearmap']) {
        // If nearmap is active, don't filter (eg: show shade) on active sites
        Map.highlightLots();
        Map.highlightLotsOutline(this.activeSiteGuids);
        return;
      }
      Map.highlightLots(this.activeSiteGuids);
      Map.highlightLotsOutline(this.activeSiteGuids);
    },
    updateFilteredSites() {
      if (this.filterStatuses['Nearmap']) {
        // If nearmap is active, don't filter (eg: show shade) on active sites
        const sitesToFilter = _.difference(_.map(this.sites, 'details.guid'), this.activeSiteGuids);
        Map.filterLots(sitesToFilter);
        return;
      }
      Map.filterLots(_.map(this.sites, 'details.guid'));
    },
    setLastSiteActivatedByClick(siteGuid: string) {
      this.lastSiteGuidActivatedByClick = siteGuid;
    },
    unsetLastSiteActivatedByClick() {
      this.lastSiteGuidActivatedByClick = null;
    }
  }
});
