




import Vue from 'vue';
import PipelineApi from '@/api/PipelineApi';

export default Vue.extend({
  data: () => ({
    stageID: '' as string,
    pipelineID: '' as string
  }),
  async mounted() {
    await this.activate();
  },
  methods: {
    async activate() {
      const response = await PipelineApi.create();
      if (response?.data) {
        this.pipelineID = response?.data.data.pipelineID;
        this.stageID = response?.data.data.stageID;
        await this.$router.push({
          name: 'PipelineStageShow',
          params: { pipelineId: this.pipelineID, stageId: this.stageID }
        });
      }
    }
  }
});
