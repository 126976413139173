import { Site } from '@/types/Site';
import { SiteAssessment, SiteAssessmentValue } from '@/types/SiteAssessment';
import _ from 'lodash';

class SiteHelpers {
  isValidAssessmentValue(value): boolean {
    return _.includes(['pass', 'fail', 'unknown'], value);
  }
  getSiteAssessment(site: Site, assessmentId: number): SiteAssessment | null {
    const siteAssessment = _.find(site.assessments, {
      assessmentId: assessmentId
    });
    return siteAssessment ?? null;
  }
  getSiteAssessmentValue(site: Site, assessmentId: number): SiteAssessmentValue {
    const siteAssessment = this.getSiteAssessment(site, assessmentId);
    if (siteAssessment && this.isValidAssessmentValue(siteAssessment.value)) return siteAssessment.value;
    return 'unknown';
  }
  getSiteAssessmentsPassCount(site: Site, assessmentIds: number[]): number {
    const activeAssessments = _.filter(site.assessments, assessment => {
      return _.includes(assessmentIds, assessment.assessmentId);
    });
    return _.filter(activeAssessments, {
      value: 'pass'
    }).length;
  }
}

export default new SiteHelpers();
