export default {
  'No Strata': {
    filterLayers: ['strata_lots']
  },
  Frontage: {
    filterLayers: ['EstStreetFrontage']
  },
  'Max Height': {
    filterLayers: ['MaxBuildHeight2DLots']
  },
  'No Flood': {
    filterLayers: ['overlay_flood']
  },
  'No Bushfire': {
    filterLayers: ['overlay_bushfire']
  },
  'No Heritage': {
    filterLayers: ['overlay_heritage']
  },
  'Low slope': {
    filterLayers: ['DetailedContours2D']
  },
  'Large Trees': {
    filterLayers: ['Nearmap']
  }
};
