import { APIHelper } from '@archistarai/auth-frontend';
import { Suburb } from '@/types/Suburb';

export default {
  async post(
    pipelineId: number,
    stageId: number,
    suburb: Suburb[],
    permittedUse: string,
    lotMin: number | null,
    lotMax: number | null,
    underdevelopedLots: boolean,
    emptyLots: boolean,
    buildingHeightMin: number | null,
    buildingHeightMax: number | null,
    subdivisionCountMin: number | null,
    subdivisionCountMax: number | null,
    gfaByFsrMin: number | null,
    gfaByFsrMax: number | null,
    frontageMin: number | null,
    frontageMax: number | null,
    zones: [] | null
  ) {
    return APIHelper.ManageAPI.put(`pipeline/${pipelineId}/add/sites/using-filter`, {
      pipelineId: pipelineId,
      stageId: stageId,
      suburb: suburb,
      arrFilters: [
        {
          id: 'PermittedUses',
          label: 'Permitted Uses',
          type: 'Select',
          options: ['ANY', 'DUPLEX', 'TOWNHOUSE', 'APARTMENT', 'MIXEDUSE'],
          values: permittedUse,
          range: []
        },
        {
          id: 'LotSize',
          label: 'Lot Size',
          type: 'number',
          min: 0,
          max: 100000,
          range: [lotMin, lotMax]
        },
        {
          id: 'EstStreetFrontage',
          label: 'Frontage',
          code: 'r',
          type: 'number',
          min: 0,
          max: 100,
          range: [frontageMin, frontageMax]
        },
        {
          id: 'HighlightUnderDevelopedLots',
          label: 'Highlight Underdeveloped Lots',
          type: 'boolean',
          toggle: underdevelopedLots,
          range: []
        },
        {
          id: 'EmptyLots',
          label: 'Empty Lots',
          type: 'boolean',
          toggle: emptyLots,
          range: []
        },
        {
          id: 'MaxBuildingHeightRange',
          label: 'Max Building Height',
          type: 'number',
          min: 0,
          max: 200,
          range: [buildingHeightMin, buildingHeightMax]
        },
        {
          id: 'TotalSubdivisionCounts',
          label: 'Total Subdivision Counts',
          type: 'number',
          min: 0,
          max: 500,
          range: [subdivisionCountMin, subdivisionCountMax]
        },
        {
          id: 'GfaByFsr',
          label: 'GFA By FSR',
          type: 'number',
          min: 0,
          max: 100000,
          range: [gfaByFsrMin, gfaByFsrMax]
        },
        {
          id: 'Zones',
          label: 'Zone',
          type: 'Select',
          options: [],
          values: zones,
          range: []
        }
      ]
    });
  }
};
