


































import Vue from 'vue';
import Map from '@/services/map';
import BaseAutocomplete from '@/components/BaseAutocomplete.vue';
import _ from 'lodash';
import GnafLotIdSearchApi from '@/api/GnafLotIdSearchApi';
import { LngLatLike } from 'mapbox-gl';
import MapPropertyIdApi from '@/api/MapPropertyIdApi';

export interface ListItem {
  text?: string | number | object;
  value?: string | number | object | LngLatLike;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
  group?: string;
}

export default Vue.extend({
  components: { BaseAutocomplete },
  data: () => ({
    searchText: '' as string,
    resultsGeocode: [] as string[],
    resultsLotIdSearch: [] as string[],
    resultCoordinateSearch: null as LngLatLike | null,
    isLoading: false as boolean
  }),
  computed: {
    items(): ListItem[] {
      // Build items array to feed into autocomplete
      const items: ListItem[] = [];
      if (!_.isEmpty(this.resultsGeocode)) {
        items.push({ divider: true });
        items.push({ header: 'Addresses' });
      }
      items.push(
        ..._.map(this.resultsGeocode, result => {
          return {
            text: result.place_name,
            value: result,
            group: 'Addresses'
          };
        })
      );

      if (!_.isEmpty(this.resultsLotIdSearch)) {
        items.push({ divider: true });
        items.push({ header: 'Lots' });
      }
      items.push(
        ..._.map(this.resultsLotIdSearch, result => {
          return {
            text: this.getLotIdSuggestionDisplay(result),
            value: result,
            group: 'Lots'
          };
        })
      );

      if (this.resultCoordinateSearch) {
        items.push({ divider: true });
        items.push({ header: 'Coordinate' });
        items.push({
          text: `${this.resultCoordinateSearch[0]}, ${this.resultCoordinateSearch[1]}`,
          value: this.resultCoordinateSearch,
          group: 'Coordinate'
        });
      }

      return items;
    }
  },
  watch: {
    searchText(val) {
      if (!val) {
        this.resetItems();
        return;
      }
      this.querySearch();
    }
  },
  methods: {
    setActiveSiteGuid(siteGuid: string, point: LngLatLike) {
      return this.$store.direct.dispatch.Site.setActiveSiteGuid({ siteGuid, point });
    },
    selectSingleSite(siteGuid: string) {
      return this.$store.direct.dispatch.Site.selectSingleSite(siteGuid);
    },
    querySearch() {
      this.geocoderSearch(this.searchText);
      this.gnafLotIdSearch(this.searchText);
      this.coordinateSearch(this.searchText);
    },
    resetItems() {
      this.resultsGeocode = [];
    },
    async geocoderSearch(query) {
      let geocodeCountry = '';
      if (this.$store.direct.state.User.appSettings?.geocodingCountry === 'undefined') {
        geocodeCountry = 'au';
      } else if (this.$store.direct.state.User.appSettings?.geocodingCountry) {
        geocodeCountry = this.$store.direct.state.User.appSettings?.geocodingCountry;
      }
      this.resultsGeocode = await Map.geocode(query, geocodeCountry);
    },
    async gnafLotIdSearch(query) {
      const response = await GnafLotIdSearchApi.store(query);
      //Ignore any results under 7 as they are not usually relevant
      this.resultsLotIdSearch = response.data.filter(result => {
        return result._score >= 7;
      });
    },
    coordinateSearch(query) {
      const coordRegexp = /^(^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))$/g;
      const match = coordRegexp.exec(query);
      if (!match || match.length !== 3) {
        this.resultCoordinateSearch = null;
        return;
      }
      this.resultCoordinateSearch = [parseFloat(match[1]), parseFloat(match[2])];
    },
    getLotIdSuggestionDisplay(result) {
      const displayString = `${result._source.street_address}, ${result._source.locality_name} ${result._source.state_abbreviation} ${result._source.postcode}`;
      return `${result._source.legal_parcel_id} - ${_.startCase(_.toLower(displayString))}`;
    },
    selected(result) {
      if (result.group === 'Addresses') {
        const isAddressType = result.value.place_type.includes('address');
        const coord: LngLatLike = [result.value.center[0], result.value.center[1]];
        this.flyToAndHighlightSite(coord, isAddressType);
      }
      if (result.group === 'Lots') {
        const coord: LngLatLike = [result.value._source.longitude, result.value._source.latitude];
        this.flyToAndHighlightSite(coord);
      }
      if (result.group === 'Coordinate') {
        const coord: LngLatLike = [result.value[1], result.value[0]];
        this.flyToAndHighlightSite(coord);
      }
    },
    async flyToAndHighlightSite(coord: LngLatLike, highlightSite = true) {
      if (highlightSite) {
        const response = await MapPropertyIdApi.store(coord);
        const siteGuid = response.data.data;
        if (!_.isEmpty(siteGuid)) {
          this.setActiveSiteGuid(siteGuid[0], coord);
          this.selectSingleSite(siteGuid[0]);
        }
      }
      Map.flyToPoint([coord[0], coord[1]], Math.max(Map.map.getZoom(), 17));
    }
  }
});
