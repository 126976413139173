import { ActionContext } from 'vuex';

interface MapState {
  currentAsLgaGuid: string | undefined;
  currentAsRegionGuid: string | undefined;
}

export default {
  namespaced: true as const,
  state: {
    currentAsLgaGuid: undefined,
    currentAsRegionGuid: undefined
  } as MapState,
  actions: {
    updateCurrentMapRegion(
      { commit }: ActionContext<MapState, any>,
      payload: { asLgaGuid: string; asRegionGuid: string }
    ): void {
      commit('UPDATE_CURRENT_MAP_REGION', payload);
    }
  },
  mutations: {
    UPDATE_CURRENT_MAP_REGION(state: MapState, payload: { asLgaGuid: string; asRegionGuid: string }): void {
      state.currentAsLgaGuid = payload.asLgaGuid;
      state.currentAsRegionGuid = payload.asRegionGuid;
    }
  },
  getters: {}
};
