









































































































































































































































































































































































































































































































































































































































































import Vue, { VueConstructor } from 'vue';
import _ from 'lodash';
import { Site } from '@/types/Site';
import { Stage } from '@/types/Stage';
import PipelineStageSiteMoveApi from '@/api/PipelineStageSiteMoveApi';
import NextStageDropdown from '@/components/NextStageDropdown.vue';
import BaseBtn from '@/components/BaseBtn.vue';
import { Pipeline } from '@/types/Pipeline';
import BaseCheckboxSimple from '@/components/BaseCheckboxSimple.vue';
import SiteHelpers from '@/services/siteHelpers';
import { PipelineConfigAssessment } from '@/types/PipelineConfigAssessment';
import MapScenarioGuidByPropGuidApi from '@/api/MapScenarioGuidByPropGuidApi';
import { AuthFeatures } from '@archistarai/auth-frontend/lib/esm/types';

const STATE = Object.freeze({
  INIT: 'init',
  MOVING_SITES: 'moving-sites',
  LOADED: 'loaded'
});

type VueExt = Vue & {
  $refs: {
    siteList: InstanceType<typeof HTMLElement>;
    headerRow: InstanceType<typeof HTMLElement>;
  };
};

export default (Vue as VueConstructor<VueExt>).extend({
  components: {
    BaseCheckboxSimple,
    NextStageDropdown,
    BaseBtn
  },
  props: {
    defaultSort: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    STATE,
    state: STATE.INIT as string,
    stagedSiteGuids: [] as string[],
    siteSearchText: '' as string,
    debouncedSiteSearchText: '' as string,
    debounceSiteSearchText: Function() as Function,
    lastSiteGuidActivatedByClick: null as string | null,
    isSearchInputExpanded: false as boolean,
    isSelectAll: false as boolean,
    sortByScore: null as string | null,
    sortByLotSize: null as string | null,
    addressCellWidth: 0 as number,
    scrollTimer: undefined as number | undefined,
    isShownExtraColumns: false as boolean
  }),
  computed: {
    canViewSiteCard(): boolean {
      return this.$authUser.hasAccessToFeatureByName(AuthFeatures['Site Card']);
    },
    activePipeline(): Pipeline | null {
      return this.$store.direct.state.Pipeline.activePipeline;
    },
    activeStageId(): number | null {
      return this.$store.direct.state.Pipeline.activeStageId;
    },
    isLastStageActive(): boolean {
      return this.$store.direct.getters.Pipeline.isLastStageActive;
    },
    stages(): readonly Stage[] {
      return this.$store.direct.getters.Pipeline.getStages;
    },
    nextStageOptions(): Stage[] {
      return _.reject(this.stages, {
        id: parseInt(this.$route.params.stageId)
      });
    },
    siteGuids(): readonly string[] {
      return _.clone(this.$store.direct.getters.Site.siteGuids);
    },
    sites(): { [siteGuid: string]: Site } {
      return this.$store.direct.state.Site.sites;
    },
    hasSites(): boolean {
      return !_.isEmpty(this.sites);
    },
    activeSiteGuids(): string[] {
      return this.$store.direct.state.Site.activeSiteGuids;
    },
    mapPaneHeight(): number {
      return this.$store.direct.state.Layout.mapPaneHeight;
    },
    tableMaxHeightCss(): string {
      const headerHeight = 88;
      const bottomPadding = 20;
      return `calc(100vh - ${headerHeight + bottomPadding + this.mapPaneHeight}px)`;
    },
    displayedSites(): Site[] {
      // Search and Filter
      let filteredSites = [..._.values(this.sites)];
      if (this.debouncedSiteSearchText) {
        filteredSites = [
          ..._.values(
            _.filter(this.sites, site => {
              return site.address.toLowerCase().includes(this.debouncedSiteSearchText.toLowerCase());
            })
          )
        ];
      }

      // Sort
      if (this.sortByScore) {
        return _.orderBy(
          filteredSites,
          [
            site => {
              return this.getSiteAssessmentsPassCount(site);
            },
            'streetAddress',
            'streetNo'
          ],
          [this.sortByScore, 'asc', 'asc']
        );
      }
      if (this.sortByLotSize) {
        return _.orderBy(
          filteredSites,
          [
            site => {
              return parseInt(site.lotSize);
            },
            'streetAddress',
            'streetNo'
          ],
          [(this.sortByLotSize, 'asc', 'asc')]
        );
      }
      return filteredSites.reverse();
    },
    selectedSiteGuids(): string[] {
      return _.reject(this.$store.direct.state.Site.selectedSiteGuids, _.isEmpty);
    },
    isAnySiteSelected(): boolean {
      return !_.isEmpty(this.selectedSiteGuids);
    },
    enabledAssessments(): readonly PipelineConfigAssessment[] {
      return this.$store.direct.getters.Pipeline.getEnabledAssessments;
    },
    adressCellWidthStyles(): { width: string; maxWidth: string; minWidth: string } {
      return {
        width: `${this.addressCellWidth}px`,
        maxWidth: `${this.addressCellWidth}px`,
        minWidth: `${this.addressCellWidth}px`
      };
    }
  },
  watch: {
    activeSiteGuids: {
      handler(value: string[]) {
        if (_.isEmpty(value)) {
          return;
        } else if (
          _.last(value) !== this.lastSiteGuidActivatedByClick &&
          this.$store.direct.getters.Site.hasSite(_.last(value))
        ) {
          // Revisit. This is extremely slow (probably because of the rerendering in the table)
          // this.$nextTick(() => {
          //   const lastActivatedSite = this.$store.direct.getters.Site.getSite(_.last(value));
          //   this.$refs.recycleScroller.scrollToItem(_.findIndex(this.displayedSites, { id: lastActivatedSite.id }));
          // });
        }
      }
    },
    siteGuids: {
      handler(newValue, oldValue) {
        if (_.size(newValue) !== _.size(oldValue)) {
          this.$store.direct.dispatch.Pipeline.setConfigDetailStageSiteCount({
            stageId: this.activeStageId,
            count: _.size(newValue)
          });
        }
      }
    },
    isAnySiteSelected: {
      handler(newValue) {
        if (newValue) {
          this.isSearchInputExpanded = false;
        }
      }
    },
    debouncedSiteSearchText: {
      handler(newValue) {
        if (newValue) {
          this.unselectAll();
        }
      }
    },
    displayedSites: {
      handler(newValue) {
        if (newValue.length === 0) {
          this.unselectAll();
        }
      }
    }
  },
  mounted() {
    this.activate();
  },
  created() {
    this.debounceSiteSearchText = _.debounce(() => {
      this.debouncedSiteSearchText = this.siteSearchText;
    }, 500);
    window.addEventListener('resize', this.calcAddressCellWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.calcAddressCellWidth);
  },
  methods: {
    updateAddSiteLimits(limits) {
      this.$store.direct.dispatch.Pipeline.setSitesInPipelineLimits(limits);
    },
    calcAddressCellWidth() {
      let headerRowChildWidth = 0;
      Array.from(this.$refs.headerRow.children).forEach(headerRowChild => {
        if (!headerRowChild.classList.contains('address-cell')) {
          headerRowChildWidth += (headerRowChild as HTMLElement).offsetWidth;
        }
      });
      this.addressCellWidth = _.max([this.$refs.siteList.offsetWidth - headerRowChildWidth - 20, 250]);
    },
    sortSitesByScore() {
      this.sortByLotSize = null;
      const sortStates = ['asc', 'desc', null];
      this.sortByScore = sortStates[(_.indexOf(sortStates, this.sortByScore) + 1) % sortStates.length];
    },
    sortSitesByLotSize() {
      this.sortByScore = null;
      const sortStates = ['asc', 'desc', null];
      this.sortByLotSize = sortStates[(_.indexOf(sortStates, this.sortByLotSize) + 1) % sortStates.length];
    },
    bubbleScrollbarClick($event) {
      // Workaround to ensure Vuetify's click-outside directive works when clicking on the table scrollbar
      // Eg: Closing all AiMenu when the scrollbar is clicked
      const $vueRecycleScroller: HTMLElement = document.getElementsByClassName(
        'vue-recycle-scroller'
      )[0] as HTMLElement;
      const $headerRow = document.getElementsByClassName('header-row')[0];
      if (
        $event.clientX > $headerRow.getBoundingClientRect().right &&
        $event.clientX < $vueRecycleScroller.getBoundingClientRect().right
      ) {
        $vueRecycleScroller.click();
      }
    },
    async activate() {
      this.state = STATE.INIT;

      if (this.defaultSort === 'score') {
        this.sortByScore = 'desc';
      }

      await this.$store.direct.dispatch.Site.getSiteList({
        pipelineId: parseInt(this.$route.params.pipelineId),
        stageId: parseInt(this.$route.params.stageId)
      });
      this.$store.direct.dispatch.Pipeline.setConfigDetailStageSiteCount({
        stageId: this.activeStageId,
        count: _.size(this.sites)
      });
      this.state = STATE.LOADED;
      this.$nextTick(() => {
        this.calcAddressCellWidth();
      });
    },
    async viewSiteCard(siteGuid: string) {
      const response = await MapScenarioGuidByPropGuidApi.store(siteGuid);
      if (response?.data) {
        const url = `${process.env.VUE_APP_URL_ARCHISTAR}/sitecard/${response.data.data.scenarioGuid}`;
        window.open(url, '_blank');
      }
    },
    getSiteAssessmentsPassCount(site: Site): number {
      return SiteHelpers.getSiteAssessmentsPassCount(site, _.map(this.enabledAssessments, 'id'));
    },
    isSiteActive(siteGuid: string): boolean {
      return this.$store.direct.getters.Site.isSiteActive(siteGuid);
    },
    activateSite(siteGuid: string) {
      this.setLastSiteActivatedByClick(siteGuid);
      this.$store.direct.dispatch.Site.setActiveSiteGuid({ siteGuid, point: null });
    },
    clearActiveSites() {
      this.unsetLastSiteActivatedByClick();
      this.$store.direct.dispatch.Site.clearActiveSites();
    },
    toggleSelectAll() {
      if (!this.isSelectAll) {
        this.selectAll();
      } else {
        this.unselectAll();
      }
    },
    selectAll() {
      this.isSelectAll = true;
      _.map(this.displayedSites, 'details.guid').forEach(siteGuid => {
        this.selectSite(siteGuid);
      });
    },
    unselectAll() {
      this.isSelectAll = false;
      this.clearSelectedSites();
    },
    toggleSiteSelect(siteGuid: string) {
      if (this.isSiteSelected(siteGuid)) {
        this.unselectSite(siteGuid);
        return;
      }
      this.selectSite(siteGuid);
    },
    selectSite(siteGuid: string) {
      this.$store.direct.dispatch.Site.selectSite(siteGuid);
    },
    unselectSite(siteGuid: string) {
      this.$store.direct.dispatch.Site.unselectSite(siteGuid);
    },
    isSiteSelected(siteGuid: string): boolean {
      return this.$store.direct.getters.Site.isSiteSelected[siteGuid];
    },
    clearSelectedSites() {
      this.$store.direct.dispatch.Site.clearSelectedSites();
    },
    isSiteStaged(siteGuid: string): boolean {
      return this.stagedSiteGuids.includes(siteGuid);
    },
    clearStagedSites() {
      this.stagedSiteGuids = [];
    },
    stageSelectedSites() {
      this.stagedSiteGuids = _.clone(this.selectedSiteGuids);
    },
    async moveToStage(stage: Stage) {
      this.stageSelectedSites();
      try {
        const stagedSiteGuids = _.reject(this.stagedSiteGuids, _.isEmpty);
        this.state = STATE.MOVING_SITES;
        if (!this.activePipeline) {
          return;
        }
        const siteLimits = await PipelineStageSiteMoveApi.update(this.activePipeline.id, stage.id, stagedSiteGuids);
        this.updateAddSiteLimits(siteLimits?.data.data);

        this.$store.direct.dispatch.Pipeline.addConfigDetailStageSiteCount({
          stageId: stage.id,
          count: stagedSiteGuids.length
        });
        this.$store.direct.dispatch.Site.removeSites(stagedSiteGuids);
        this.clearSelectedSites();
        this.unselectAll();
      } catch (error) {
        // todo: Error handling
        console.log(error);
      } finally {
        this.clearStagedSites();
        this.state = STATE.LOADED;
      }
    },
    async moveToFinalStage() {
      await this.moveToStage(_.last(this.stages));
    },
    setLastSiteActivatedByClick(siteGuid: string) {
      this.lastSiteGuidActivatedByClick = siteGuid;
    },
    unsetLastSiteActivatedByClick() {
      this.lastSiteGuidActivatedByClick = null;
    },
    onScroll() {
      // Workaround for performance issue with big list in RecycleScroller.
      // Debounce the rendering of those extra columns on scroll
      // And show a (lighter) loader in the meantime
      this.isShownExtraColumns = false;
      const refresh = () => {
        this.isShownExtraColumns = true;
        this.$forceUpdate();
      };
      this.$nextTick(() => {
        clearTimeout(this.scrollTimer);
        this.scrollTimer = setTimeout(refresh, 500);
      });
    },
    handleClick(site) {
      this.activateSite(site.details.guid);
    },
    buildCsvRow(site) {
      const note = site.note ? site.note : '';
      let row = site.address.replaceAll(',', ' ').replaceAll('  ', ' ');
      row += ',"' + site.details.point.latitude + '"';
      row += ',"' + site.details.point.longitude + '"';
      row += ',"' + site.lotSize + '"';
      row += ',"' + note + '"';
      return row;
    },
    exportCsv(sites) {
      let csv = 'ID, Latitude, Longitude, Lot size, Notes\n';
      csv += sites
        .map(site => {
          return this.buildCsvRow(site);
        })
        .join('\n');

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'archistar-pipelines-export.csv';
      anchor.click();
    },
    exportCsvAll() {
      this.exportCsv(this.displayedSites);
    },
    exportCsvSelected() {
      const selectedSites = this.displayedSites.filter(site => {
        return _.includes(this.selectedSiteGuids, site.details.guid);
      });
      this.exportCsv(selectedSites);
    }
  }
});
