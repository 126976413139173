var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$attrs['label'])?_c('label',{staticClass:"block font-medium leading-5 text-grey-500 mb-2",attrs:{"for":_vm.$attrs['id']}},[_vm._v(_vm._s(_vm.$attrs['label']))]):_vm._e(),_c('div',{staticClass:"editor rounded-lg border",class:{
      'border-grey-200': !_vm.isFocused,
      'border-grey-400': _vm.isFocused
    }},[_c('div',[_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var commands = ref.commands;
    var isActive = ref.isActive;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menu === 'editor'),expression:"menu === 'editor'"}],staticClass:"menubar bg-grey-300 p-2"},[_c('div',{staticClass:"grid grid-cols-12 gap-1"},[_c('ai-btn',{class:{ 'is-active': isActive.bold() },attrs:{"small":"","color":"secondary"},on:{"click":commands.bold}},[_c('span',{},[_c('ai-icon-svg',{attrs:{"icon":"text-bold","width":"1.25rem","height":"1.25rem"}})],1)]),_c('ai-btn',{staticClass:"grid-cols-1",class:{ 'is-active': isActive.italic() },attrs:{"small":"","color":"secondary"},on:{"click":commands.italic}},[_c('span',{},[_c('ai-icon-svg',{attrs:{"icon":"text-italic","width":"1.25rem","height":"1.25rem"}})],1)]),_c('ai-btn',{staticClass:"grid-cols-1",class:{ 'is-active': isActive.underline() },attrs:{"small":"","color":"secondary"},on:{"click":commands.underline}},[_c('span',{},[_c('ai-icon-svg',{attrs:{"icon":"text-underline","width":"1.25rem","height":"1.25rem"}})],1)])],1)])]}}])})],1),_c('editor-content',{staticClass:"editor__content prose",style:(_vm.editorStyles),attrs:{"editor":_vm.editor}}),(_vm.menu === 'basic')?_c('div',{staticStyle:{"min-height":"44px"}},[_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var commands = ref.commands;
    var isActive = ref.isActive;
    var focused = ref.focused;
return [_c('transition',{attrs:{"enter-active-class":"transition ease-out duration-500 transform","enter-class":"opacity-0 translate-y-4","enter-to-class":"opacity-100 translate-y-0","leave-active-class":"transition ease-out duration-200 transform","leave-class":"opacity-100 translate-y-0","leave-to-class":"opacity-0 translate-y-4"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(focused),expression:"focused"}],staticClass:"menubar rounded-b-lg py-1 bg-grey-50"},[_c('ai-btn',{staticClass:"menubar__button",class:{ 'is-active': isActive.bold() },attrs:{"icon":"","text":""},on:{"click":commands.bold}},[_c('ai-icon',[_vm._v("mdi-format-bold")])],1),_c('ai-btn',{staticClass:"menubar__button",class:{ 'is-active': isActive.italic() },attrs:{"icon":"","text":""},on:{"click":commands.italic}},[_c('ai-icon',[_vm._v("mdi-format-italic")])],1),_c('ai-btn',{staticClass:"menubar__button",class:{ 'is-active': isActive.bullet_list() },attrs:{"icon":"","text":""},on:{"click":commands.bullet_list}},[_c('ai-icon',[_vm._v("mdi-format-list-bulleted")])],1),_c('ai-btn',{staticClass:"menubar__button",class:{ 'is-active': isActive.ordered_list() },attrs:{"icon":"","text":""},on:{"click":commands.ordered_list}},[_c('ai-icon',[_vm._v("mdi-format-list-numbered")])],1)],1)])]}}],null,false,3079073)})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }