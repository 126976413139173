import { ActionContext } from 'vuex';
import { AppSettings } from '@/types/AppSettings';

interface UserState {
  appSettings: AppSettings | undefined;
  userLoaded: boolean;
}

export default {
  namespaced: true as const,
  state: {
    appSettings: undefined,
    userLoaded: false
  } as UserState,
  actions: {
    updateAppSettings({ commit }: ActionContext<UserState, any>, payload: AppSettings): void {
      commit('UPDATE_APP_SETTINGS', payload);
    },
    setUserLoaded({ commit }: ActionContext<UserState, any>): void {
      commit('SET_USER_LOADED');
    }
  },
  mutations: {
    UPDATE_APP_SETTINGS(state: UserState, payload: AppSettings): void {
      state.appSettings = payload;
    },
    SET_USER_LOADED(state: UserState): void {
      state.userLoaded = true;
    }
  },
  getters: {}
};
