
















































































































































































































































import Vue, { PropType } from 'vue';
import { AuthFeatures } from '@archistarai/auth-frontend/lib/esm/types';
import BaseBtn from '@/components/BaseBtn.vue';
import MapScenarioGuidByPropGuidApi from '@/api/MapScenarioGuidByPropGuidApi';

interface Site {
  id: number;
  address: string;
  lotSize: number;
  zone: string;
}

export default Vue.extend({
  components: { BaseBtn },
  props: {
    site: {
      type: Object as PropType<Site>,
      required: true
    },
    siteGuid: {
      type: String,
      required: true
    },
    isLoadingAddress: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {
    canViewSiteCard(): boolean {
      return this.$authUser.hasAccessToFeatureByName(AuthFeatures['Site Card']);
    }
  },
  methods: {
    async goToMainApp(target: string, newTab = false) {
      const response = await MapScenarioGuidByPropGuidApi.store(this.siteGuid);
      if (response?.data) {
        const url = `${process.env.VUE_APP_URL_ARCHISTAR}/${target}/${response.data.data.scenarioGuid}`;
        if (!newTab) {
          window.location.href = url;
        } else {
          window.open(url, '_blank');
        }
      }
    }
  }
});
