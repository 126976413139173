import { Site } from '@/types/Site';
import { ActionContext } from 'vuex';
import PipelineStageSiteApi from '@/api/PipelineStageSiteApi';
import SiteHelpers from '@/services/siteHelpers';
import PipelineSiteAssessmentApi from '@/api/PipelineSiteAssessmentApi';
import _ from 'lodash';
import Vue from 'vue';
import { LngLatLike } from 'mapbox-gl';

interface SiteState {
  activeSiteId: number | null;
  sites: { [siteGuid: string]: Site };
  activeSiteGuids: string[];
  firstActiveSitePoint: LngLatLike | null; // LatLng of first site in activeSiteGuids. So we can position the map popup.
  selectedSiteGuids: string[];
}

const getDefaultState = () => {
  return {
    activeSiteId: null,
    sites: {},
    activeSiteGuids: [],
    firstActiveSitePoint: null,
    selectedSiteGuids: []
  } as SiteState;
};

export default {
  namespaced: true as const,
  state: getDefaultState(),
  actions: {
    reset({ commit }) {
      commit('RESET_STATE');
    },
    async getSiteList(
      { commit }: ActionContext<SiteState, any>,
      payload: { pipelineId: number; stageId: number }
    ): Promise<void> {
      const response = await PipelineStageSiteApi.index(payload.pipelineId, payload.stageId);
      commit('SET_SITES', _.keyBy(response?.data.data, 'details.guid'));
    },
    addSites({ commit }: ActionContext<SiteState, any>, sites: Site[]): void {
      commit('ADD_SITES', _.keyBy(sites, 'details.guid'));
    },
    setActiveSiteGuid(
      { commit, getters }: ActionContext<SiteState, any>,
      { siteGuid, point = null }: { siteGuid: string; point: LngLatLike | null }
    ): void {
      if (!point && getters.hasSite(siteGuid)) {
        const site = getters.getSite(siteGuid);
        point = [site.details.point.longitude, site.details.point.latitude];
      }
      commit('SET_FIRST_ACTIVE_SITE_POINT', point);
      commit('SET_ACTIVE_SITE_GUID', siteGuid);
    },
    appendActiveSiteGuid({ commit }: ActionContext<SiteState, any>, siteGuid: string): void {
      commit('APPEND_ACTIVE_SITE_GUID', siteGuid);
    },
    removeActiveSiteGuid({ commit }: ActionContext<SiteState, any>, siteGuid: string): void {
      commit('REMOVE_ACTIVE_SITE_GUID', siteGuid);
    },
    clearActiveSites({ commit }: ActionContext<SiteState, any>): void {
      commit('CLEAR_ACTIVE_SITES');
    },
    selectSingleSite({ commit, getters }: ActionContext<SiteState, any>, siteGuid: string): void {
      if (!getters.hasSite(siteGuid)) {
        return;
      }
      commit('SET_SELECTED_SITE_GUID', siteGuid);
    },
    selectSite({ commit, getters }: ActionContext<SiteState, any>, siteGuid: string): void {
      if (!getters.hasSite(siteGuid)) {
        return;
      }
      commit('APPEND_SELECTED_SITE_GUID', siteGuid);
    },
    unselectSite({ commit }: ActionContext<SiteState, any>, siteGuid: string): void {
      commit('REMOVE_SELECTED_SITE_GUID', siteGuid);
    },
    removeSites({ commit }: ActionContext<SiteState, any>, siteGuids: string[]): void {
      commit('REMOVE_SITES', siteGuids);
    },
    clearSelectedSites({ commit }: ActionContext<SiteState, any>): void {
      commit('CLEAR_SELECTED_SITES');
    },
    async updateSiteAssessmentValue(
      { commit, getters }: ActionContext<SiteState, any>,
      { value, siteGuid, assessmentId, pipelineId }
    ) {
      const site = getters.getSite(siteGuid);
      try {
        commit('SET_SITE_ASSESSMENT', { siteGuid, assessmentId, value });
        await PipelineSiteAssessmentApi.update(pipelineId, site.id, assessmentId, value);
      } catch (e) {
        commit('SET_SITE_ASSESSMENT', { siteGuid, assessmentId, value: 'unknown' });
      }
    },
    async bulkUpdateSiteAssessmentValue(
      { dispatch }: ActionContext<SiteState, any>,
      { value, siteGuids, assessmentId, pipelineId }
    ) {
      // todo: Merge this into a single BE call for performance
      _.forEach(siteGuids, siteGuid => {
        dispatch('updateSiteAssessmentValue', { value, siteGuid, assessmentId, pipelineId });
      });
    },
    setSiteNote({ commit }: ActionContext<SiteState, any>, { siteGuid, note }) {
      commit('SET_SITE_NOTE', { siteGuid, note });
    }
  },
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_SITES: (state: SiteState, sites: { [siteGuid: string]: Site }): void => {
      state.sites = sites;
    },
    ADD_SITES: (state: SiteState, sites: { [siteGuid: string]: Site }): void => {
      _.forEach(sites, (site, siteGuid) => {
        Vue.set(state.sites, siteGuid, site);
      });
    },
    REMOVE_SITES: (state: SiteState, siteGuids: string[]): void => {
      _.forEach(siteGuids, siteGuids => {
        Vue.delete(state.sites, siteGuids);
      });
    },
    CLEAR_ACTIVE_SITES: (state: SiteState): void => {
      state.activeSiteGuids = [];
    },
    SET_FIRST_ACTIVE_SITE_POINT: (state: SiteState, point: LngLatLike): void => {
      state.firstActiveSitePoint = point;
    },
    CLEAR_FIRST_ACTIVE_SITE_POINT: (state: SiteState): void => {
      state.firstActiveSitePoint = null;
    },
    SET_ACTIVE_SITE_GUID: (state: SiteState, siteGuid: string): void => {
      state.activeSiteGuids = [siteGuid];
    },
    APPEND_ACTIVE_SITE_GUID: (state: SiteState, siteGuid: string): void => {
      state.activeSiteGuids.push(siteGuid);
    },
    REMOVE_ACTIVE_SITE_GUID: (state: SiteState, siteGuid: string): void => {
      const index = state.activeSiteGuids.indexOf(siteGuid);
      if (index > -1) {
        state.activeSiteGuids.splice(index, 1);
      }
    },
    SET_SELECTED_SITE_GUID: (state: SiteState, siteGuid: string): void => {
      state.selectedSiteGuids = [siteGuid];
    },
    APPEND_SELECTED_SITE_GUID: (state: SiteState, siteGuid: string): void => {
      state.selectedSiteGuids.push(siteGuid);
    },
    REMOVE_SELECTED_SITE_GUID: (state: SiteState, siteGuid: string): void => {
      const index = state.selectedSiteGuids.indexOf(siteGuid);
      if (index > -1) {
        state.selectedSiteGuids.splice(index, 1);
      }
    },
    CLEAR_SELECTED_SITES: (state: SiteState): void => {
      state.selectedSiteGuids = [];
    },
    SET_SITE_ASSESSMENT: (state: SiteState, { siteGuid, assessmentId, value }): void => {
      const siteAssessment = SiteHelpers.getSiteAssessment(state.sites[siteGuid], assessmentId);
      if (siteAssessment) {
        Vue.set(siteAssessment, 'value', value);
      }
    },
    SET_SITE_NOTE: (state: SiteState, { siteGuid, note }): void => {
      Vue.set(state.sites[siteGuid], 'note', note);
    }
  },
  getters: {
    siteGuids: (state: SiteState): string[] => {
      return _.keys(state.sites);
    },
    hasSite: (state: SiteState, getters: any) => (siteGuid: string): boolean => {
      return getters.getSite(siteGuid) !== undefined;
    },
    getSite: (state: SiteState) => (siteGuid: string): Site | undefined => {
      return state.sites[siteGuid];
    },
    getSiteGuids: (state: SiteState): string[] => {
      return _.keys(state.sites);
    },
    isSiteActive: (state: SiteState) => (siteGuid: string): boolean => {
      return _.includes(state.activeSiteGuids, siteGuid);
    },
    isSiteSelected: state =>
      state.selectedSiteGuids.reduce((out, siteGuid: string) => {
        out[siteGuid] = _.includes(state.selectedSiteGuids, siteGuid);
        return out;
      }, {})
  }
};
