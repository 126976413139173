



















































































































































































































































































































































































import Vue from 'vue';
import BaseBtn from '@/components/BaseBtn.vue';

export default Vue.extend({
  components: { BaseBtn },
  data: () => ({
    isShownDialog: false,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    // eslint-disable-next-line no-unused-vars
    resolve: (value: boolean) => {
      return;
    },
    // eslint-disable-next-line no-unused-vars
    reject: (value: boolean) => {
      return;
    },
    /* eslint-enable @typescript-eslint/no-unused-vars */
    message: '' as string,
    title: '' as string,
    buttonText: '' as string
  }),
  methods: {
    open(title: string, message: string, buttonText: string) {
      this.title = title;
      this.message = message;
      this.buttonText = buttonText;
      this.isShownDialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.isShownDialog = false;
    },
    cancel() {
      this.resolve(false);
      this.isShownDialog = false;
    }
  }
});
