import { Pipeline } from '@/types/Pipeline';
import _ from 'lodash';
import { Stage } from '@/types/Stage';
import { PipelineConfigAssessment } from '@/types/PipelineConfigAssessment';
import { ActionContext } from 'vuex';
import Vue from 'vue';

interface SitesInPipelineLimits {
  canAddSites: false;
  remaining: number | null;
  batchLimit: number | null;
  totalSetLimit: number | null;
  batchSetLimit: number | null;
}

interface PipelineState {
  activePipeline: Pipeline | null;
  activeStageId: number | null;
  activeAssessmentId: number | null;
  sitesInPipelineLimits: SitesInPipelineLimits | null;
}

export default {
  namespaced: true as const,
  state: {
    activePipeline: null,
    activeStageId: null,
    activeAssessmentId: null,
    sitesInPipelineLimits: {
      canAddSites: false,
      remaining: 0,
      batchLimit: 100,
      totalSetLimit: 500,
      batchSetLimit: 100
    }
  } as PipelineState,
  actions: {
    setActivePipeline({ commit }: ActionContext<PipelineState, any>, pipeline: Pipeline): void {
      commit('SET_ACTIVE_PIPELINE', pipeline);
    },
    setActiveStageId({ commit }: ActionContext<PipelineState, any>, stageId: number): void {
      commit('SET_ACTIVE_STAGE_ID', stageId);
    },
    setConfigDetailStageSiteCount({ commit }: ActionContext<PipelineState, any>, { stageId, count }): void {
      commit('SET_STAGE_SITE_COUNT', { stageId, count: count });
    },
    addConfigDetailStageSiteCount({ commit, getters }: ActionContext<PipelineState, any>, { stageId, count }): void {
      const siteCount = getters.getConfigDetailStage(stageId).siteCount;
      commit('SET_STAGE_SITE_COUNT', { stageId, count: siteCount + count });
    },
    subtractConfigDetailStageSiteCount(
      { commit, getters }: ActionContext<PipelineState, any>,
      { stageId, count }
    ): void {
      const siteCount = getters.getConfigDetailStage(stageId).siteCount;
      commit('SET_STAGE_SITE_COUNT', { stageId, count: siteCount - count });
    },
    setActiveAssessment({ commit }: ActionContext<PipelineState, any>, assessmentId: number): void {
      commit('SET_ACTIVE_ASSESSMENT', assessmentId);
    },
    clearActiveAssessment({ commit }: ActionContext<PipelineState, any>): void {
      commit('SET_ACTIVE_ASSESSMENT', null);
    },
    setSitesInPipelineLimits({ commit }: ActionContext<PipelineState, any>, limits: SitesInPipelineLimits): void {
      commit('SET_SITES_IN_PIPELINE_LIMITS', limits);
    }
  },
  mutations: {
    SET_ACTIVE_PIPELINE: (state: PipelineState, pipeline: Pipeline): void => {
      state.activePipeline = pipeline;
    },
    SET_ACTIVE_STAGE_ID: (state: PipelineState, stageId: number): void => {
      state.activeStageId = stageId;
    },
    SET_ACTIVE_ASSESSMENT: (state: PipelineState, assessmentId: number | null): void => {
      state.activeAssessmentId = assessmentId;
    },
    SET_STAGE_SITE_COUNT: (state: PipelineState, { stageId, count }): void => {
      if (!state.activePipeline) {
        return;
      }
      const stageConfig = _.find(state.activePipeline.configDetails.stages, {
        stage: { id: stageId }
      });
      Vue.set(stageConfig, 'siteCount', count);
    },
    SET_SITES_IN_PIPELINE_LIMITS: (state: PipelineState, limits): void => {
      state.sitesInPipelineLimits = {
        canAddSites: limits.canAddSites,
        remaining: limits.remaining,
        batchLimit: limits.batchLimit,
        totalSetLimit: limits.totalSetLimit,
        batchSetLimit: limits.batchSetLimit
      };
    }
  },
  getters: {
    getStage: (state: PipelineState, getters: any) => (stageId: number): Stage => {
      return _.find(getters.getStages, { id: stageId });
    },
    getActiveStage: (state: PipelineState, getters: any): Stage => {
      return getters.getStage(state.activeStageId);
    },
    getFirstStage: (state: PipelineState, getters: any): Stage => {
      return getters.getStages[0];
    },
    getLastStage: (state: PipelineState, getters: any): Stage => {
      return _.last(getters.getStages);
    },
    getStages: (state: PipelineState): Stage[] => {
      if (!state.activePipeline) {
        return [];
      }
      return _.map(state.activePipeline.configDetails.stages, 'stage');
    },
    isFirstStageActive: (state: PipelineState, getters: any): boolean => {
      return state.activeStageId === getters.getFirstStage.id;
    },
    isLastStageActive: (state: PipelineState, getters: any): boolean => {
      return state.activeStageId === getters.getLastStage.id;
    },
    getConfigDetailStage: (state: PipelineState) => (stageId: number): any | null => {
      if (!state.activePipeline) {
        return null;
      }
      return _.find(state.activePipeline.configDetails.stages, {
        stage: { id: stageId }
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getAssessments: (state: PipelineState): PipelineConfigAssessment[] => {
      if (!state.activePipeline) {
        return [];
      }
      return _.sortBy(state.activePipeline.configDetails.assessments, 'sequence');
    },
    getEnabledAssessments: (state: PipelineState, getters: any): PipelineConfigAssessment[] => {
      return getters.getAssessments;
      // return _.filter(getters.getAssessments, {});
    },
    getActiveAssessment: (state: PipelineState, getters: any): PipelineConfigAssessment | null => {
      if (!state.activeStageId || !state.activeAssessmentId) {
        return null;
      }
      return _.find(getters.getAssessments, { id: state.activeAssessmentId });
    },
    getSitesInPipelineLimits: (state: PipelineState): SitesInPipelineLimits | null => {
      return state.sitesInPipelineLimits;
    }
  }
};
