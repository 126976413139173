import Vue from 'vue';
import Vuex from 'vuex';
import { createDirectStore } from 'direct-vuex';
import FilterLayer from './modules/filterLayer.store';
import Layout from './modules/layout.store';
import Map from './modules/map.store';
import Pipeline from './modules/pipeline.store';
import Site from './modules/site.store';
import User from './modules/user.store';
import SiteCard from './modules/siteCard.store';
Vue.use(Vuex);

export interface RootState {
  loading: boolean;
}

const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } = createDirectStore({
  state: {
    loading: false
  } as RootState,
  mutations: {
    SET_LOADING: (state: RootState, payload: boolean) => {
      state.loading = payload;
    }
  },
  modules: {
    FilterLayer,
    Layout,
    Map,
    Pipeline,
    Site,
    User,
    SiteCard
  }
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions and getters.
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext };

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module 'vuex' {
  interface Store<S> {
    direct: AppStore;
  }
}
