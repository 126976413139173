import { APIHelper } from '@archistarai/auth-frontend';
import { SiteCard } from '@/types/SiteCard';

export default {
  //SiteCard
  async index(siteId: number) {
    return APIHelper.ManageAPI.get(`site-card/${siteId}`);
  },
  async getOrNew(siteId: number) {
    return APIHelper.ManageAPI.get(`pipeline/1/site/${siteId}/site-card`);
  },
  async saveCard(siteCard: SiteCard) {
    return APIHelper.ManageAPI.put('site-card/' + siteCard.id, siteCard);
  },
  //Sections
  async getPermittedUses(siteCardId: number) {
    return APIHelper.ManageAPI.get(`site-card/${siteCardId}/permitted-uses`);
  },
  async getLayerDetails(siteCardId: number, type: string) {
    return APIHelper.ManageAPI.post(`site-card/${siteCardId}/permitted-uses`, type);
  },
  async calculateFeasibility(siteCardId: number, details: []) {
    return APIHelper.ManageAPI.post(`site-card/${siteCardId}/permitted-uses`, details);
  },
  //Attachments
  async getAttachmentList(siteCard: SiteCard) {
    return APIHelper.ManageAPI.get('site-card-attachment/' + siteCard.id);
  }
};
