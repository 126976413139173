import { APIHelper } from '@archistarai/auth-frontend';

interface PipelineStoreData {
  name: string;
}
interface PipelineUpdateData {
  id: number;
  name: string;
}

export default {
  async create() {
    return APIHelper.ManageAPI.get('pipeline/create');
  },
  async index() {
    return APIHelper.ManageAPI.get('pipeline');
  },
  async show(pipelineId: number) {
    try {
      return await APIHelper.ManageAPI.get(`pipeline/${pipelineId}`);
    } catch (e) {
      return e.response.status;
    }
  },
  async store(pipeline: PipelineStoreData) {
    return APIHelper.ManageAPI.post('pipeline', pipeline);
  },
  async update(pipeline: PipelineUpdateData) {
    return APIHelper.ManageAPI.put(`pipeline/${pipeline.id}`, pipeline);
  },
  async destroy(pipelineId: number) {
    return APIHelper.ManageAPI.delete(`pipeline/${pipelineId}`);
  },
  async get(pipelineId: number) {
    return APIHelper.ManageAPI.get(`pipeline/${pipelineId}/filters`);
  },
  async subscribeAlerts(pipelineId: number) {
    return APIHelper.ManageAPI.put(`pipeline/${pipelineId}/alerts/subscribe`);
  },
  async unsubscribeAlerts(pipelineId: number) {
    return APIHelper.ManageAPI.put(`pipeline/${pipelineId}/alerts/unsubscribe`);
  }
};
