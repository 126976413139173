import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import DesignSystem from '@archistarai/design-system/packages/archistar';
import './styles/tailwind.css';
import { APIHelper, AuthUser, Analytics } from '@archistarai/auth-frontend/src';
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import vClickOutside from 'v-click-outside';

Vue.config.productionTip = false;

Vue.use(vClickOutside);

// Auto-register all base components
const requireComponent = require.context('../src/components', true, /Base[A-Z]\w+\.(vue|js)$/);
requireComponent.keys().forEach(function(fileName) {
  let baseComponentConfig = requireComponent(fileName);
  baseComponentConfig = baseComponentConfig.default || baseComponentConfig;
  const baseComponentName = baseComponentConfig.name || fileName.replace(/^.+\//, '').replace(/\.\w+$/, '');
  Vue.component(baseComponentName, baseComponentConfig);
});

Vue.component('RecycleScroller', RecycleScroller);

Vue.prototype.$authUser = Vue.observable(AuthUser);
Vue.prototype.$APIHelper = APIHelper;

if (process.env.VUE_APP_ENV === 'production') {
  Analytics.manage.init();
}

new Vue({
  router,
  store: store.original,
  vuetify: DesignSystem,
  render: h => h(App)
}).$mount('#app');
