




















import Vue from 'vue';

export default Vue.extend({
  props: {
    menuProps: {
      type: Object
    }
  },
  computed: {
    combinedMenuProps(): Record<string, any> {
      return Object.assign({ nudgeTop: '0px', offsetY: true }, this.menuProps);
    }
  }
});
