import { RouteConfig } from 'vue-router/types/router';
import PipelineStageShow from '@/views/Pipeline/PipelineStageShow.vue';
import PipelineShow from '@/views/Pipeline/PipelineShow.vue';
import PipelineIndex from '@/views/Pipeline/PipelineIndex.vue';

export default [
  {
    path: '/pipelines',
    name: 'PipelineIndex',
    component: PipelineIndex
  },
  {
    path: '/pipelines/:pipelineId',
    name: 'PipelineShow',
    component: PipelineShow,
    children: [
      {
        path: 'stages/:stageId',
        name: 'PipelineStageShow',
        component: PipelineStageShow
      }
    ]
  }
] as Array<RouteConfig>;
