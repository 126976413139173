

































































import Vue from 'vue';
import _ from 'lodash';
import { Site } from '@/types/Site';
import { Pipeline } from '@/types/Pipeline';
import SiteHelpers from '@/services/siteHelpers';
import { SiteAssessmentValue } from '@/types/SiteAssessment';
import { PipelineConfigAssessment } from '@/types/PipelineConfigAssessment';
import AssessmentSlider from '@/components/AssessmentSlider.vue';
import BaseBtn from '@/components/BaseBtn.vue';
import PipelineAddSites from '@/api/PipelineAddSites';
import SiteDetailsApi from '@/api/SiteDetailsApi';
import { Stage } from '@/types/Stage';

export default Vue.extend({
  components: { BaseBtn, AssessmentSlider },
  data: () => ({
    isShown: false as boolean,
    isLoading: false as boolean,
    siteAddresses: {} as { string: string }
  }),
  computed: {
    isAllSitesInPipeline(): boolean {
      return _.intersection(this.pipelineSiteGuids, this.activeSiteGuids).length === this.activeSiteGuids.length;
    },
    displayedSiteAssessmentValue(): string {
      const assessmentValues: SiteAssessmentValue = _.map(this.activeSiteGuids, siteGuid => {
        const site = this.$store.direct.getters.Site.getSite(siteGuid);
        if (!site || !this.activeAssessmentId) {
          return 'unknown';
        }
        return SiteHelpers.getSiteAssessmentValue(site, this.activeAssessmentId);
      });
      if (_.every(assessmentValues, assessmentValue => assessmentValue === 'pass')) {
        return 'pass';
      } else if (_.every(assessmentValues, assessmentValue => assessmentValue === 'fail')) {
        return 'fail';
      }
      return 'unknown';
    },
    activeSiteGuids(): string[] {
      return _.clone(this.$store.direct.state.Site.activeSiteGuids);
    },
    displayedSiteGuids(): string[] {
      return this.activeSiteGuids.slice(0, 5);
    },
    totalOverflowSites(): number {
      return _.max([this.activeSiteGuids.length - this.displayedSiteGuids.length, 0]);
    },
    pipelineSiteGuids(): readonly string[] {
      return this.$store.direct.getters.Site.getSiteGuids;
    },
    sitesNotInPipelineGuids(): string[] {
      return _.difference(this.activeSiteGuids, this.pipelineSiteGuids);
    },
    totalSitesNotInPipeline(): number {
      return this.sitesNotInPipelineGuids.length;
    },
    activePipeline(): Pipeline | null {
      return this.$store.direct.state.Pipeline.activePipeline;
    },
    activeAssessment(): PipelineConfigAssessment | null {
      return this.$store.direct.getters.Pipeline.getActiveAssessment;
    },
    activeAssessmentId(): number | null {
      return this.$store.direct.state.Pipeline.activeAssessmentId;
    },
    isFirstStageActive(): boolean {
      return this.$store.direct.getters.Pipeline.isFirstStageActive;
    },
    getFirstStage(): Stage {
      return this.$store.direct.getters.Pipeline.getFirstStage;
    },
    activeStageId(): number | null {
      return this.$store.direct.state.Pipeline.activeStageId;
    }
  },
  watch: {
    activeSiteGuids: {
      handler(newValue, oldValue) {
        // Ping BE Api to get addresses for sites that're not in the pipeline
        const newSiteGuids = newValue.filter(siteGuid => {
          return oldValue.indexOf(siteGuid) === -1;
        });
        newSiteGuids.forEach(newSiteGuid => {
          if (!this.hasSite(newSiteGuid)) {
            this.fetchSiteAddressFromApi(newSiteGuid);
          }
        });

        this.isShown = newValue.length > 1;
      }
    }
  },
  methods: {
    async fetchSiteAddressFromApi(siteGuid: string) {
      const response = await SiteDetailsApi.show(siteGuid);
      this.$set(this.siteAddresses, siteGuid, response.data.data);
    },
    hasSite(siteGuid: string): boolean {
      return this.$store.direct.getters.Site.hasSite(siteGuid);
    },
    getSite(siteGuid: string): Site | undefined {
      return this.$store.direct.getters.Site.getSite(siteGuid);
    },
    addSites(sites: Site[]) {
      if (this.isFirstStageActive) {
        this.$store.direct.dispatch.Site.addSites(sites);
        return;
      }
      this.$store.direct.dispatch.Pipeline.addConfigDetailStageSiteCount({
        stageId: this.getFirstStage.id,
        count: sites.length
      });
    },
    async bulkUpdateSiteAssessmentValue(value: string) {
      if (!this.activeAssessmentId || !this.activePipeline) {
        return;
      }
      this.$store.direct.dispatch.Site.bulkUpdateSiteAssessmentValue({
        value,
        siteGuids: this.activeSiteGuids,
        assessmentId: this.activeAssessmentId,
        pipelineId: this.activePipeline.id
      });
    },
    getAddressForSiteNotInPipeline(siteGuid) {
      return siteGuid in this.siteAddresses ? this.siteAddresses[siteGuid] : null;
    },
    async addSitesToPipeline() {
      if (!this.activePipeline) return;
      try {
        this.isLoading = true;
        const response = await PipelineAddSites.store(this.activePipeline.id, this.sitesNotInPipelineGuids);
        this.addSites(response?.data.data.sites);
        this.$emit('sites-added');
      } finally {
        this.isLoading = false;
      }
    }
  }
});
