







import Vue from 'vue';

export default Vue.extend({
  props: {
    variant: {
      type: String,
      default: 'primary'
    }
  }
});
