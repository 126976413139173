
















import Vue from 'vue';
import BaseBtn from '@/components/BaseBtn.vue';
import BaseSiteDetails from '@/components/BaseSiteDetails.vue';
import PipelineAddSites from '@/api/PipelineAddSites';
import { Pipeline } from '@/types/Pipeline';
import { Site } from '@/types/Site';
import SiteDetailsApi from '@/api/SiteDetailsApi';
import { Stage } from '@/types/Stage';

export default Vue.extend({
  components: { BaseBtn, BaseSiteDetails },
  props: {
    siteGuid: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isLoadingAddress: true as boolean,
    isAddingSite: false as boolean,
    site: {} as {
      id: number;
      guid: string;
      address: string;
      lotSize: number;
      zone: string;
    }
  }),
  async mounted() {
    try {
      if (this.siteGuid) {
        const response = await SiteDetailsApi.show(this.siteGuid);
        this.site = response.data.data;
      }
    } finally {
      this.isLoadingAddress = false;
    }
  },
  computed: {
    activePipeline(): Pipeline | null {
      return this.$store.direct.state.Pipeline.activePipeline;
    },
    isFirstStageActive(): boolean {
      return this.$store.direct.getters.Pipeline.isFirstStageActive;
    },
    getFirstStage(): Stage {
      return this.$store.direct.getters.Pipeline.getFirstStage;
    },
    activeStageId(): number | null {
      return this.$store.direct.state.Pipeline.activeStageId;
    }
  },
  methods: {
    addSites(sites: Site[]) {
      if (this.isFirstStageActive) {
        this.$store.direct.dispatch.Site.addSites(sites);
        return;
      }
      this.$store.direct.dispatch.Pipeline.addConfigDetailStageSiteCount({
        stageId: this.getFirstStage.id,
        count: sites.length
      });
    },
    async addSiteToPipeline() {
      if (!this.activePipeline) return;
      try {
        this.isAddingSite = true;
        const response = await PipelineAddSites.store(this.activePipeline.id, [this.siteGuid]);
        this.addSites(response?.data.data.sites);
        this.$emit('site-added');
      } finally {
        this.isAddingSite = false;
      }
    },
    close() {
      this.$emit('close');
    }
  }
});
