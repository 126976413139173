
















import Vue from 'vue';
import SiteOpportunities from '@/components/SiteOpportunities.vue';
import SiteDisqualifications from '@/components/SiteDisqualifications.vue';
import { Stage } from '@/types/Stage';

export default Vue.extend({
  components: { SiteOpportunities, SiteDisqualifications },
  mounted() {
    this.setActiveStageId(parseInt(this.$route.params.stageId));
  },
  computed: {
    stages(): readonly Stage[] {
      return this.$store.direct.getters.Pipeline.getStages;
    }
  },
  methods: {
    setActiveStageId(stageId: number) {
      this.$store.direct.dispatch.Pipeline.setActiveStageId(stageId);
    }
  }
});
