import { APIHelper } from '@archistarai/auth-frontend';

export default {
  async update(pipelineId: number, stageId: number, siteGuids: string[]) {
    return APIHelper.ManageAPI.put(`pipeline/${pipelineId}/move/sites`, {
      sites: siteGuids,
      pipelineId: pipelineId,
      stageId: stageId
    });
  }
};
