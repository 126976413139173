























































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import _ from 'lodash';
import BaseDialog from '@/components/BaseDialog.vue';
import BaseBtn from '@/components/BaseBtn.vue';
import { Pipeline } from '@/types/Pipeline';
import BaseTextField from '@/components/BaseTextField.vue';
import ImportSiteFilterListApi from '@/api/ImportSiteFilterListApi';
import FiltersSitesSuburbListApi from '@/api/FiltersSitesSuburbListApi';
import FiltersSitesCountApi from '@/api/FiltersSitesCountApi';
// import { Suburb } from '@/types/Suburb';
import PipelineAddSitesUsingFilterApi from '@/api/PipelineAddSitesUsingFilterApi';
import FiltersSitesZoneListApi from '@/api/FiltersSitesZoneListApi';
import { Zone } from '@/types/Zone';
import axios, { CancelTokenSource } from 'axios';
import SiteSearch from '@/components/ImportSite/SiteSearch.vue';
import SvgAddSites from '@/components/SvgAddSites.vue';
import { AuthFeatures } from '@archistarai/auth-frontend/lib/esm/types';

export default Vue.extend({
  components: {
    SvgAddSites,
    SiteSearch,
    BaseDialog,
    BaseBtn,
    BaseTextField
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    cancelTokenSource: null as null | CancelTokenSource,
    debounceSuggestionSelected: Function() as Function,
    pipeline: {} as Pipeline,
    isCreating: false as boolean,
    isManySites: false as boolean,
    suburbRequired: false as boolean,
    sitesLoading: false as boolean,
    addSitesLoading: false as boolean,
    filterList: [] as any,
    suburbsData: [] as any,
    zonesData: [] as any,
    allData: [] as any,
    searchQuery: '' as string,
    searchZoneQuery: '' as string,
    subValue: '' as string,
    zoneValue: '' as string,
    source: '' as any,
    currentSelected: -1,
    currSelected: -1,
    selectedSuburbUses: [] as any,
    selectedZones: [] as Zone[],
    inputFocused: false as boolean,
    zoneFocused: false as boolean,
    insideSuggestions: false as boolean,
    zoneSuggestions: false as boolean,
    clearOnBlur: false as boolean,
    totalSitesCount: 0 as number,
    sitesCountReturned: false as boolean,
    allowedSitesCount: 0 as number,
    isSitesAdded: false as boolean,
    selectedItem: 1 as number,
    searchLimit: 10 as number,
    permittedUsesSelected: [] as any,
    permittedUsesItem: [
      {
        id: 'ALL',
        name: 'All',
        selected: false
      },
      {
        id: 'TOWNHOUSE',
        name: 'Townhouse',
        selected: false
      },
      {
        id: 'APARTMENT',
        name: 'Apartment',
        selected: false
      },
      {
        id: 'MIXEDUSE',
        name: 'Mixed Use',
        selected: false
      },
      {
        id: 'DUPLEX',
        name: 'Duplex',
        selected: false
      }
      // {
      //   id: 'OTHER',
      //   name: 'Other',
      //   selected: false
      // }
    ],
    lotSizeList: [
      {
        id: 'Lot5',
        label: 'Manual',
        text: 'Manual',
        selected: false
      },
      {
        id: 'Lot1',
        label: 'Small',
        text: '100-2000m²',
        selected: false
      },
      {
        id: 'Lot2',
        label: 'Medium',
        text: '2000-10000m²',
        selected: false
      },
      {
        id: 'Lot3',
        label: 'Large',
        text: '1-4ha',
        selected: false
      },
      {
        id: 'Lot4',
        label: 'Extra Large',
        text: '4-100ha',
        selected: false
      }
    ],
    lotSizeSelected: [] as any,
    lotSizeInput: false as boolean,
    lotSizeMin: null as number | null,
    lotSizeMax: null as number | null,
    frontageMin: null as number | null,
    frontageMax: null as number | null,
    buildingHeightMin: null as number | null,
    buildingHeightMax: null as number | null,
    subdivisionCountMin: null as number | null,
    subdivisionCountMax: null as number | null,
    gfaByFsrMin: null as number | null,
    gfaByFsrMax: null as number | null,
    underdevelopedLots: false as boolean,
    emptyLots: false as boolean,
    advanceFilter: false as boolean,
    zoneResults: [] as any,
    zoneSelected: [] as any,
    suburbsGlobalSearch: Function() as Function,
    zoneSearch: Function() as Function,
    zoneExist: 0 as number
  }),
  computed: {
    isShownDialog: {
      get(): boolean {
        return this.value;
      },
      set(isShownDialog: boolean) {
        this.$emit('input', isShownDialog);
      }
    },
    canViewPermittedUseFlags(): boolean {
      return this.$authUser.hasAccessToFeatureByName(AuthFeatures['Permitted Use Flags']);
      // return this.$authUser.hasAccessToResource('Permitted Use Flags');
    },
    pipelineId(): number | null {
      if (this.$store.direct?.state && this.$store.direct.state.Pipeline.activePipeline) {
        return this.$store.direct.state.Pipeline.activePipeline.id;
      }
      return null;
    },
    sitesInPipelineLimits(): any {
      return this.$store.direct.state.Pipeline.sitesInPipelineLimits;
    },
    enableAddSitesButton(): boolean {
      return this.totalSitesCount > 0 && this.sitesInPipelineLimits.canAddSites && !this.addSitesLoading;
    },
    addingSitesButtonText(): string {
      const sitesTotalCnt = this.totalSitesCount;
      if (this.totalSitesCount < this.sitesInPipelineLimits.batchSetLimit) {
        return 'Add ' + this.totalSitesCount + ' of ' + sitesTotalCnt + ' sites';
      }
      if (this.sitesCountReturned && this.totalSitesCount < 1) {
        return 'Add sites';
      }
      if (
        this.sitesInPipelineLimits.remaining > this.sitesInPipelineLimits.batchSetLimit &&
        this.sitesInPipelineLimits.batchSetLimit > 0
      ) {
        return 'Add ' + this.sitesInPipelineLimits.batchSetLimit + ' of ' + sitesTotalCnt + ' sites';
      }
      if (
        this.sitesInPipelineLimits.remaining < this.sitesInPipelineLimits.batchSetLimit &&
        this.sitesInPipelineLimits.batchSetLimit > 0 &&
        this.sitesInPipelineLimits.remaining > 0
      ) {
        return 'Add ' + this.sitesInPipelineLimits.remaining + ' of ' + sitesTotalCnt + ' sites';
      }
      return 'Add sites';
    },
    addingSitesInfoMessage(): string {
      if (this.totalSitesCount > this.sitesInPipelineLimits.remaining && this.sitesInPipelineLimits.remaining <= 50) {
        return (
          this.totalSitesCount +
          ' new sites found. You’re reaching your ' +
          this.sitesInPipelineLimits.totalSetLimit +
          ' sites capacity on the Opportunities list.'
        );
      } else if (
        this.totalSitesCount > 0 &&
        this.sitesInPipelineLimits.remaining <= this.sitesInPipelineLimits.totalSetLimit
      ) {
        return this.totalSitesCount + ' new sites found. We recommend you filter your search further.';
      }

      return 'No sites found.';
    },
    addingSitesWarningMessage(): string {
      if (this.sitesInPipelineLimits) {
        return (
          'Opportunities has a capacity of ' +
          this.sitesInPipelineLimits.totalSetLimit +
          '. Move sites to another stage to add more.'
        );
      }
      return '';
    },
    showAddingSitesInfoMessage(): boolean {
      if (!this.sitesInPipelineLimits.canAddSites && this.sitesCountReturned) {
        return false;
      }
      if (this.totalSitesCount < this.sitesInPipelineLimits.batchSetLimit) {
        if (this.selectedSuburbUses.length > 0 && this.sitesCountReturned && this.totalSitesCount === 0) {
          return true;
        } else {
          return false;
        }
      }

      return this.sitesCountReturned;
    },
    showAddingSitesWarningMessage(): boolean {
      return !this.sitesInPipelineLimits.canAddSites && this.sitesCountReturned;
    }
  },
  async mounted() {
    // await this.getFilters();
  },
  created() {
    this.suburbsGlobalSearch = _.debounce(() => {
      this.performGlobalSearch();
    }, 300);
    this.zoneSearch = _.debounce(() => {
      this.performZoneSearch();
    }, 300);
    this.debounceSuggestionSelected = _.debounce(this.suggestionSelected, 500);
  },
  methods: {
    async resetAddSites() {
      this.emptyLots = false;
      this.lotSizeMin = null;
      this.lotSizeMax = null;
      this.buildingHeightMin = null;
      this.buildingHeightMax = null;
      this.lotSizeSelected = [];
      this.permittedUsesSelected = [];
      this.subdivisionCountMin = null;
      this.subdivisionCountMax = null;
      this.gfaByFsrMin = null;
      this.gfaByFsrMax = null;
      this.frontageMin = null;
      this.frontageMax = null;
      this.lotSizeInput = false;
      this.selectedSuburbUses = [];
      this.underdevelopedLots = false;
      this.zoneSelected = [];
      this.zoneResults = [];
      this.selectedZones = [];
      this.suburbRequired = false;
      this.totalSitesCount = 0;
      this.sitesCountReturned = false;
    },
    close() {
      this.suburbRequired = false;
      this.$emit('input', false);
    },
    updateAddSiteLimits(limits) {
      this.$store.direct.dispatch.Pipeline.setSitesInPipelineLimits(limits);
    },
    async selectedLotSize(id, value, name) {
      this.lotSizeMin = null;
      this.lotSizeMax = null;

      if (id === 'Lot1') {
        this.lotSizeInput = false;
        if (value) {
          this.lotSizeMin = 100;
          this.lotSizeMax = 2000;
          if (this.lotSizeSelected[0] !== name) {
            this.lotSizeSelected = [];
            this.lotSizeSelected.push(name);
          } else {
            this.lotSizeSelected.push(name);
          }
        } else {
          this.lotSizeSelected = [];
        }
      } else if (id === 'Lot2') {
        this.lotSizeInput = false;
        if (value) {
          this.lotSizeMin = 2000;
          this.lotSizeMax = 10000;
          if (this.lotSizeSelected[0] !== name) {
            this.lotSizeSelected = [];
            this.lotSizeSelected.push(name);
          } else {
            this.lotSizeSelected.push(name);
          }
        } else {
          this.lotSizeSelected = [];
        }
      } else if (id === 'Lot3') {
        this.lotSizeInput = false;
        if (value) {
          this.lotSizeMin = 10000;
          this.lotSizeMax = 40000;
          if (this.lotSizeSelected[0] !== name) {
            this.lotSizeSelected = [];
            this.lotSizeSelected.push(name);
          } else {
            this.lotSizeSelected.push(name);
          }
        } else {
          this.lotSizeSelected = [];
        }
      } else if (id === 'Lot4') {
        this.lotSizeInput = false;
        if (value) {
          this.lotSizeMin = 40000;
          this.lotSizeMax = 1000000;
          if (this.lotSizeSelected[0] !== name) {
            this.lotSizeSelected = [];
            this.lotSizeSelected.push(name);
          } else {
            this.lotSizeSelected.push(name);
          }
        } else {
          this.lotSizeSelected = [];
        }
      } else if (id === 'Lot5') {
        if (value) {
          this.lotSizeInput = true;
          if (this.lotSizeSelected[0] !== name) {
            this.lotSizeSelected = [];
            this.lotSizeSelected.push(name);
          } else {
            this.lotSizeSelected.push(name);
          }
        } else {
          this.lotSizeInput = false;
          this.lotSizeSelected = [];
        }
      }

      await this.suggestionSelected(-1);
    },
    async updateSelectedUse(id, name) {
      const idxClickedValue = this.permittedUsesSelected.findIndex(val => val === name);
      const indexOfAny = this.permittedUsesSelected.findIndex(val => val === 'All');
      if (name === 'All') {
        //All clicked
        if (indexOfAny === -1) {
          //ANY not on - turn rest off, only ANY
          this.permittedUsesSelected = [];
          this.permittedUsesSelected.push('All');
        } else {
          //ANY on, turn off
          this.permittedUsesSelected.splice(indexOfAny);
        }
      } else if (idxClickedValue > -1) {
        // Other value clicked & was on, turn off
        this.permittedUsesSelected.splice(idxClickedValue, 1);
        // if (this.permittedUsesSelected.length == 0) {
        //   this.permittedUsesSelected.push('All');
        // }
      } else if (name !== 'All') {
        // Other value clicked & was off, turn on & make sure any is off
        if (indexOfAny > -1) {
          this.permittedUsesSelected.splice(indexOfAny, 1);
        }
        this.permittedUsesSelected.push(name);
      }

      await this.suggestionSelected(-1);
      // input box show/hide
      // if (this.selectedInsights.indexOf('Other') >= 0) {
      //   this.insightInput = true;
      // } else {
      //   this.insightInput = false;
      // }
    },
    async getFilters() {
      const response = await ImportSiteFilterListApi.getFormData();
      this.filterList = response?.data.arrFilters;
    },

    async performGlobalSearch() {
      if (this.searchQuery.length >= 3) {
        const response = await FiltersSitesSuburbListApi.post(this.searchQuery, this.selectedSuburbUses);
        this.allData = response?.data.results;
        this.suburbsData = this.allData.slice(0, this.searchLimit);
        if (!this.suburbsData[0]) this.currentSelected = -1;
      }
    },

    async performZoneSearch() {
      if (this.searchZoneQuery.length >= 1) {
        if (this.selectedSuburbUses.length > 0) {
          const zoneList = await FiltersSitesZoneListApi.post(
            this.selectedSuburbUses,
            this.searchZoneQuery,
            this.zoneSelected
          );

          this.zoneResults = zoneList?.data.results;
          this.zoneFocused = true;
        } else {
          this.suburbRequired = true;
        }
      } else {
        this.zoneFocused = false;
      }
    },
    // escPressed() {
    //   // this.suburbsData = [];
    // },
    inputInit(val) {
      // this.suburbsData = [];
      if (val === 'zone') {
        this.currSelected = -1;
      } else {
        this.currentSelected = -1;
      }
    },
    increaseIndex(val = false, type = 'suburb') {
      if (type === 'suburb') {
        if (!val && this.currentSelected > 0) this.currentSelected--;
        else if (
          val &&
          this.currentSelected < this.suburbsData.length - 1 &&
          this.suburbsData[this.currentSelected + 1].index > -1
        )
          this.currentSelected++;
      }

      // if (type === 'zone') {
      //   console.log('this.zoneResults@@', this.zoneResults.length);
      //   if (!val && this.currSelected > 0) this.currSelected--;
      //   else if (
      //     val &&
      //     this.currSelected < this.zoneResults.length - 1 &&
      //     this.zoneResults[this.currSelected + 1].index > -1
      //   )
      //     this.currSelected++;
      // }
    },

    suggestZoneSelected(item) {
      this.selectedZones.push({
        value: item.value,
        text: item.text
      });
      this.debounceSuggestionSelected(-1);
    },
    async suggestionSelected(selectedItem) {
      if (selectedItem !== -1) {
        this.selectedSuburbUses.push({
          as_locality_guid: this.suburbsData[selectedItem].guid,
          label: this.suburbsData[selectedItem].label
        });
      }
      this.getSiteCount();
    },
    async getSiteCount() {
      if (this.selectedZones.length > 0) {
        this.zoneSelected = this.selectedZones;
      }
      if (this.selectedSuburbUses.length > 0) {
        this.suburbRequired = false;
        this.sitesLoading = true;
        this.searchQuery = '';
        this.zoneSuggestions = false;
        this.searchZoneQuery = '';
        this.zoneFocused = false;
        this.inputFocused = false;

        this.suburbsData = [];
        this.insideSuggestions = false;

        const siteFrontageMin = this.frontageMax && !this.frontageMin ? 0 : this.frontageMin;
        const siteFrontageMax = this.frontageMin && !this.frontageMax ? 100 : this.frontageMax;
        const siteBHMin = this.buildingHeightMax && !this.buildingHeightMin ? 0 : this.buildingHeightMin;
        const siteBHMax = this.buildingHeightMin && !this.buildingHeightMax ? 200 : this.buildingHeightMax;
        const siteSubCountMin = this.subdivisionCountMax && !this.subdivisionCountMin ? 0 : this.subdivisionCountMin;
        const siteSubCountMax = this.subdivisionCountMin && !this.subdivisionCountMax ? 500 : this.subdivisionCountMax;
        const siteGfaByFsrMin = this.gfaByFsrMax && !this.gfaByFsrMin ? 0 : this.gfaByFsrMin;
        const siteGfaByFsrMax = this.gfaByFsrMin && !this.gfaByFsrMax ? 100000 : this.gfaByFsrMax;
        const siteLotSizeMin = this.lotSizeMax && !this.lotSizeMin ? 0 : this.lotSizeMin;
        const siteLotSizeMax = this.lotSizeMin && !this.lotSizeMax ? 1000000 : this.lotSizeMax;
        this.sitesCountReturned = false;

        // Prevent race ajaxs. Cancel previous ones first.
        if (this.cancelTokenSource) {
          this.cancelTokenSource?.cancel();
        }
        const CancelToken = axios.CancelToken;
        this.cancelTokenSource = CancelToken.source();

        try {
          const siteCountRes = await FiltersSitesCountApi.post(
            this.selectedSuburbUses,
            this.permittedUsesSelected,
            siteLotSizeMin,
            siteLotSizeMax,
            this.underdevelopedLots,
            this.emptyLots,
            siteBHMin,
            siteBHMax,
            siteSubCountMin,
            siteSubCountMax,
            siteGfaByFsrMin,
            siteGfaByFsrMax,
            siteFrontageMin,
            siteFrontageMax,
            this.zoneSelected,
            this.pipelineId,
            {
              cancelToken: this.cancelTokenSource.token
            }
          );
          this.sitesLoading = false;
          this.updateAddSiteLimits(siteCountRes?.data.data);
          this.totalSitesCount = siteCountRes?.data.data.count;
          this.sitesCountReturned = true;
        } catch (thrown) {
          if (!axios.isCancel(thrown)) {
            this.sitesLoading = false;
          }
        }
      } else {
        this.suburbRequired = true;
      }
    },
    async addFilteredSites() {
      this.addSitesLoading = true;

      const siteFrontageMin = this.frontageMax && !this.frontageMin ? 0 : this.frontageMin;
      const siteFrontageMax = this.frontageMin && !this.frontageMax ? 100 : this.frontageMax;
      const siteBHMin = this.buildingHeightMax && !this.buildingHeightMin ? 0 : this.buildingHeightMin;
      const siteBHMax = this.buildingHeightMin && !this.buildingHeightMax ? 200 : this.buildingHeightMax;
      const siteSubCountMin = this.subdivisionCountMax && !this.subdivisionCountMin ? 0 : this.subdivisionCountMin;
      const siteSubCountMax = this.subdivisionCountMin && !this.subdivisionCountMax ? 500 : this.subdivisionCountMax;
      const siteGfaByFsrMin = this.gfaByFsrMax && !this.gfaByFsrMin ? 0 : this.gfaByFsrMin;
      const siteGfaByFsrMax = this.gfaByFsrMin && !this.gfaByFsrMax ? 100000 : this.gfaByFsrMax;
      const siteLotSizeMin = this.lotSizeMax && !this.lotSizeMin ? 0 : this.lotSizeMin;
      const siteLotSizeMax = this.lotSizeMin && !this.lotSizeMax ? 1000000 : this.lotSizeMax;

      const addSites = await PipelineAddSitesUsingFilterApi.post(
        parseInt(this.$route.params.pipelineId),
        parseInt(this.$route.params.stageId),
        this.selectedSuburbUses,
        this.permittedUsesSelected,
        siteLotSizeMin,
        siteLotSizeMax,
        this.underdevelopedLots,
        this.emptyLots,
        siteBHMin,
        siteBHMax,
        siteSubCountMin,
        siteSubCountMax,
        siteGfaByFsrMin,
        siteGfaByFsrMax,
        siteFrontageMin,
        siteFrontageMax,
        this.zoneSelected
      );
      this.isSitesAdded = addSites?.data.success;
      this.updateAddSiteLimits(addSites?.data.data);
      if (this.isSitesAdded) {
        this.$emit('siteAdded');
        this.addSitesLoading = false;
        this.close();
        await this.getSiteCount();
      }
    },
    updateInputFocus(val = true, type = 'suburb') {
      if (val) {
        if (type === 'suburb') {
          if (this.insideSuggestions) return;
          if (this.searchQuery) this.inputInit(type);
          setTimeout(() => {
            this.inputFocused = true;
          }, 100);
        }
      } else {
        if (type === 'suburb') {
          if (this.insideSuggestions) return;
          setTimeout(() => {
            this.inputFocused = false;
          }, 100);
          // this.escPressed();
        }
      }
    },
    removeAllSuburbSelectedUses() {
      this.selectedSuburbUses = [];
      this.searchQuery = '';
      this.totalSitesCount = 0;
    },
    async removeSuburbUseFromSearch(useIndex) {
      this.selectedSuburbUses.splice(useIndex, 1);
      this.searchQuery = '';
      if (this.selectedSuburbUses.length < 1) {
        this.totalSitesCount = 0;
        this.suburbRequired = false;
      }
      await this.debounceSuggestionSelected(-1);
    },
    async removeZonesFromSearch(useIndex) {
      this.zoneSelected.splice(useIndex, 1);
      this.searchZoneQuery = '';
      await this.debounceSuggestionSelected(-1);
    }
  }
});
