import { APIHelper, AuthUser } from '@archistarai/auth-frontend';
declare const Buffer;

const default_image_width = 660;
const default_image_height = 845;

class ImageCreator {
  async createPropertyOverlayImageURL(
    propertyGuid: string,
    overlay: string,
    otherParams = undefined as string | undefined,
    width = default_image_width as number,
    height = default_image_height as number
  ) {
    const countryCode = AuthUser.getActiveCountry();
    let imageURL = `${process.env.VUE_APP_IMAGE_URL}?property=${propertyGuid}&asCtryCode=${countryCode}`;
    let hasLegend = false;
    if (typeof overlay !== 'undefined' && overlay) {
      imageURL += `&overlay=${overlay}`;
      hasLegend = true;
    }
    if (typeof otherParams !== 'undefined' && otherParams) {
      imageURL += otherParams;
    }

    const buff = Buffer.from(imageURL, 'utf-8');
    return APIHelper.ExternalAPI.get(
      `${process.env.VUE_APP_IMAGE_SERVICE_URL}?src=${buff.toString(
        'base64'
      )}&width=${width}&height=${height}&hasLegend=${hasLegend}`,
      {
        headers: {
          Authorization: 'Bearer ' + APIHelper.getCurrentWebToken(),
          'X-as-country-code': countryCode
        }
      }
    );
  }
}

export default new ImageCreator();
