import { APIHelper } from '@archistarai/auth-frontend';
import { LngLatLike } from 'mapbox-gl';

export default {
  async store(coord: LngLatLike) {
    return APIHelper.ArchistarAPI.post('map/getPropertyGuidsFromGeometry', {
      geometry: [{ type: 'Point', coordinates: coord }]
    });
  }
};
