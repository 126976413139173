





























































































import Vue from 'vue';
import Loader from '@/components/Common/Loader.vue';
import MapLayerToggleNotifications from '@/components/MapLayerToggleNotifications.vue';
import { FilterLayerStatus } from '@/types/FilterLayerStatus';
import { FilterLayer } from '@/types/FilterLayer';
import _ from 'lodash';

export default Vue.extend({
  components: {
    Loader,
    MapLayerToggleNotifications
  },
  computed: {
    showLayerList: {
      get(): boolean {
        return this.$store.direct.state.FilterLayer.showLayerList;
      },
      set(value: boolean) {
        this.$store.direct.commit.FilterLayer.TOGGLE_LAYER_LIST(value);
      }
    },
    filterLayersLoading(): boolean {
      return this.$store.direct.state.FilterLayer.filterLayersLoading;
    },
    filterLayers(): FilterLayer[] {
      return this.$store.direct.state.FilterLayer.filterLayers;
    },
    filterStatuses(): FilterLayerStatus {
      return this.$store.direct.state.FilterLayer.filterStatuses;
    },
    mapPaneHeight(): number {
      return this.$store.direct.state.Layout.mapPaneHeight;
    },
    totalLayersOn(): number {
      return _.filter(this.filterStatuses, filterStatus => {
        return filterStatus === true;
      }).length;
    }
  },
  async mounted() {
    await this.activate();
  },
  methods: {
    async activate() {
      this.$store.direct.dispatch.FilterLayer.getFilterLayers();
    },
    toggleLayersList() {
      this.$store.direct.commit.FilterLayer.TOGGLE_LAYER_LIST();
    }
  }
});
