import { ActionContext } from 'vuex';

interface LayoutState {
  mapPaneHeight: number;
}

export default {
  namespaced: true as const,
  state: {
    mapPaneHeight: 0
  } as LayoutState,
  actions: {
    setMapPaneHeight({ commit }: ActionContext<LayoutState, any>, height: number): void {
      commit('SET_MAP_PANE_HEIGHT', height);
    }
  },
  mutations: {
    SET_MAP_PANE_HEIGHT: (state: LayoutState, height: number): void => {
      state.mapPaneHeight = height;
    }
  },
  getters: {}
};
