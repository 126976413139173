import mapboxgl, { EventData, LngLatLike, MapLayerEventType, Popup, RequestParameters } from 'mapbox-gl';
import store from '@/store/index';
import { APIHelper } from '@archistarai/auth-frontend';
import _ from 'lodash';

class Map {
  token: string;
  container: string;
  map!: mapboxgl.Map;
  mapLoaded = false as boolean;
  popups = [] as Popup[];

  constructor() {
    this.token = mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
    this.container = 'map';
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async once(event: string, callback = () => {}) {
    return new Promise(resolve => {
      this.map?.once(event, () => {
        callback();
        resolve(true);
      });
    });
  }
  async initialise(centerLat = -33.86785, centerLon = 151.20732) {
    this.map = new mapboxgl.Map({
      container: this.container,
      style: 'mapbox://styles/archistarr/ckcpqmkqf0bk41imi6n5swmdz',
      minZoom: 0,
      maxZoom: 24,
      zoom: 15,
      center: {
        lat: centerLat,
        lng: centerLon
      },
      boxZoom: false,
      pitch: 0,
      bearing: 0,
      transformRequest: (url: string): RequestParameters => {
        if (
          url.match('https://api.archistar.ai/') ||
          url.match('https://staging-api.archistar.io/') ||
          url.match('https://datasets.archistar.ai') ||
          url.match('https://datasets-backend.prod.au.archistar.ai') ||
          url.match('https://datasets-backend.dev.au.archistar.io') ||
          url.match(
            'https://wuzq9b4hgd.execute-api.ap-southeast-2.amazonaws.com/archistar-tile-layers-tiles-retrieval-dev'
          ) || // new stack tile server v2 - staging
          url.match('https://5dhmuf70vi.execute-api.ap-southeast-2.amazonaws.com/S3TilesGateway') || // new stack tile server - staging
          url.match(
            'https://zqt51l6032.execute-api.ap-southeast-2.amazonaws.com/archistar-tile-layers-tiles-retrieval-prod'
          ) || // new stack tile server v2 - prod
          url.match('https://upsn7jrdhi.execute-api.ap-southeast-2.amazonaws.com/S3TilesGateway') // new stack tile server - prod
        ) {
          const headers = {
            Authorization: 'Bearer ' + APIHelper.getCurrentWebToken()
          } as any;
          if (
            url.match(
              'https://wuzq9b4hgd.execute-api.ap-southeast-2.amazonaws.com/archistar-tile-layers-tiles-retrieval-dev'
            ) ||
            url.match('https://5dhmuf70vi.execute-api.ap-southeast-2.amazonaws.com/S3TilesGateway') ||
            url.match(
              'https://zqt51l6032.execute-api.ap-southeast-2.amazonaws.com/archistar-tile-layers-tiles-retrieval-prod'
            ) ||
            url.match('https://upsn7jrdhi.execute-api.ap-southeast-2.amazonaws.com/S3TilesGateway')
          ) {
            headers.Accept = ['application/octet-stream', 'application/json'];
          }
          return {
            url,
            headers
          };
        }

        return { url } as RequestParameters;
      }
    });

    if (process.env.NODE_ENV !== 'production') {
      window.map = this.map;
    }

    await this.once('style.load');
    if (this.map) {
      this.map.resize();

      this.addMapControls();
      this.addSkyLayer();
      this.addArchistarLayers().then(() => {
        this.mapLoaded = true;
      });
    }
  }
  isMapReady() {
    return this.mapLoaded && this.map && this.map.getLayer('Lots');
  }
  async waitForMapReady() {
    while (!this.isMapReady()) {
      await new Promise(r => setTimeout(r, 100));
    }
    return true;
  }
  getMapboxInstance() {
    return this.map;
  }
  resizeMap() {
    this.map.resize();
  }
  flyToPoint(point: any, zoom: number) {
    this.map.flyTo({
      center: point,
      zoom: zoom,
      duration: 1000
    });
  }
  addMapControls() {
    this.map.addControl(
      new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: 'metric'
      }),
      'top-right'
    );
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: true }), 'bottom-right');
    //this.map.addControl(new PitchToggle({ minPitchZoom: 11 }), 'bottom-right');
    //this.map.addControl(new Screenshot(), 'bottom-right');
    //this.map.addControl(new MeasureToggle(), 'bottom-right');
    //this.map.addControl(drawMethods.getDrawReference(), 'bottom-right');
  }
  addSkyLayer() {
    //Add sky layer
    this.map.addLayer({
      id: 'sky',
      type: 'sky',
      paint: {
        // set up the sky layer to use a color gradient
        'sky-type': 'gradient',
        // the sky will be lightest in the center and get darker moving radially outward
        // this simulates the look of the sun just below the horizon
        'sky-gradient': [
          'interpolate',
          ['linear'],
          ['sky-radial-progress'],
          0.8,
          'rgba(135, 206, 235, 1.0)',
          1,
          'rgba(0,0,0,0.1)'
        ],
        'sky-gradient-center': [0, 0],
        'sky-gradient-radius': 90,
        'sky-opacity': ['interpolate', ['exponential', 0.1], ['zoom'], 5, 0, 22, 1]
      }
    } as any);
  }
  toggleTerrainLayer(toggle: boolean) {
    if (toggle) {
      if (!this.map.getSource('mapbox-dem')) {
        this.map.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
          tileSize: 512,
          maxzoom: 14
        });
      }
      // add the DEM source as a terrain layer with exaggerated height
      (this.map as any)?.setTerrain({
        source: 'mapbox-dem',
        exaggeration: 1.5
      });
    } else {
      (this.map as any)?.setTerrain(null);
    }
  }
  addArchistarLayers() {
    this.map.setLayoutProperty('housenum-label', 'visibility', 'none');
    /* Add Archistar sources */
    return new Promise((resolve, reject) => {
      APIHelper.ArchistarAPI.post('/map/getlayersourcelist').then(response => {
        if (response && Object.prototype.hasOwnProperty.call(response.data, 'sources')) {
          response.data.sources.forEach(source => {
            this.map.addSource(source.name, source.props);
          });
          resolve(true);
        }
        reject(new Error('Failed to get map sources'));
      });
      /* Add Archistar Layers */
    }).then(() => {
      APIHelper.ArchistarAPI.post('/map/getlayerlist').then(response => {
        if (response && Object.prototype.hasOwnProperty.call(response.data, 'layers')) {
          response.data.layers.forEach(layer => {
            const { style } = layer;
            this.map.addLayer(layer, 'contours-labels');
            if (style) {
              this.map.setPaintProperty(layer.id, style.property, style.value);
            }
          });

          //Do this twice as sometimes layers take a while to load
          this.updateRegionGuid();
          setTimeout(() => {
            this.updateRegionGuid();
          }, 1500);
          setTimeout(() => {
            this.updateRegionGuid();
          }, 3000);
        }
      });
    });
  }
  async toggleArchistarLayer(layerName: string, status: boolean | null) {
    await this.waitForMapReady();
    if (!this.map) return;

    const lyr = this.map.getLayer(layerName);
    if (lyr) {
      if (status == null) {
        status = this.map.getLayoutProperty(layerName, 'visibility') !== 'visible';
      }

      this.map.setLayoutProperty(layerName, 'visibility', status ? 'visible' : 'none');
    }
  }
  updateRegionGuid() {
    if (!this.map.getLayer('lga')) return;
    const features = this.map.queryRenderedFeatures(this.map.project(this.map.getCenter()), {
      layers: ['lga']
    });
    // This might fail if the center of the map is in the ocean/river
    if (
      features &&
      features[0] &&
      features[0].properties &&
      features[0].properties.as_region_guid &&
      features[0].properties.as_lga_guid
    ) {
      store.dispatch.Map.updateCurrentMapRegion({
        asLgaGuid: features[0].properties.as_lga_guid,
        asRegionGuid: features[0].properties.as_region_guid
      });
    }
  }
  hasLayer(layerName: string): boolean {
    if (!this.isMapReady()) return false;
    return typeof this.map.getLayer(layerName) !== undefined;
  }
  async updateLayerFilter(layerName: string, filterExp: any[] | undefined) {
    await this.waitForMapReady();
    if (!this.isMapReady()) return;

    if (!this.map.getLayer(layerName)) return;

    if (filterExp) {
      this.map.setFilter(layerName, filterExp);
    }
  }
  async updateLayerStyles(layerName: string, paintExp: object | undefined, layoutExp: object | undefined) {
    if (!this.isMapReady()) return;

    if (!this.map.getLayer(layerName)) return;

    if (paintExp) {
      Object.keys(paintExp).forEach(x => {
        this.map.setPaintProperty(layerName, x, paintExp[x]);
      });
    }

    if (layoutExp) {
      Object.keys(layoutExp).forEach(x => {
        this.map.setLayoutProperty(layerName, x, layoutExp[x]);
      });
    }
  }
  async removeMapLayer(layerName: string) {
    if (!this.isMapReady()) return;
    if (this.map.getLayer(layerName)) {
      this.map.removeLayer(layerName);
      while (this.map.getLayer(layerName)) {
        await new Promise(r => setTimeout(r, 100));
      }
    }
  }
  addPopUp(point: LngLatLike, options: mapboxgl.PopupOptions): Popup {
    this.clearAllPopups();
    const popup = new mapboxgl.Popup(options).setLngLat(point).addTo(this.map);
    this.popups.push(popup);
    return popup;
  }

  clearAllPopups() {
    _.forEach(this.popups, popup => {
      popup.remove();
    });
  }

  bindClick(layer, listener: (ev: MapLayerEventType['click'] & EventData) => void) {
    if (!layer) {
      this.map.on('click', listener);
      return;
    }
    this.map.on('click', layer, listener);
  }

  setLayerArray(layerName: string, arrPropGuids: string[] = []) {
    if (!this.map.getLayer(layerName)) return;
    if (arrPropGuids) {
      const expr = ['in', ['get', 'guid'], ['literal', arrPropGuids] as any];
      this.map.setFilter(layerName, expr);
    } else {
      this.map.setFilter(layerName, false);
    }
  }

  filterLots(arrPropGuids: string[] = []) {
    this.setLayerArray('FilteredLots', arrPropGuids);
  }

  highlightLots(arrPropGuids: string[] = []) {
    this.setLayerArray('HighlightLots', arrPropGuids);
  }

  highlightLotsOutline(arrPropGuids: string[] = []) {
    this.setLayerArray('HighlightLotsOutline', arrPropGuids);
  }

  async geocode(query: string, country = '', limit = 10) {
    const center = this.map.getCenter();
    const GEOCODING_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
    const searchQuery = `${encodeURIComponent(query)}.json`;
    const response = await APIHelper.ExternalAPI.get(
      `${GEOCODING_BASE_URL + searchQuery}?access_token=${this.token}&limit=${limit}&proximity=${center.lng},${
        center.lat
      }&country=${country}`
    );

    if (!response) {
      return [];
    }
    if (response.data.features) {
      return response.data.features;
    }
  }
}

export default new Map();
