import { APIHelper } from '@archistarai/auth-frontend';
import { ActionContext } from 'vuex';
import { FilterLayerStatus } from '@/types/FilterLayerStatus';
import { FilterLayer } from '@/types/FilterLayer';

interface FilterLayerState {
  filterLayers: FilterLayer[];
  filterStatuses: FilterLayerStatus;
  filterLayersLoading: boolean;
  showLayerList: boolean;
}

export default {
  namespaced: true as const,
  state: {
    filterLayers: [],
    filterStatuses: {},
    filterLayersLoading: false,
    showLayerList: false
  } as FilterLayerState,
  actions: {
    getFilterLayers({ commit }: ActionContext<FilterLayerState, any>) {
      commit('SET_FILTER_LAYERS_LOADING', true);
      commit('RESET_FILTER_LAYERS');

      APIHelper.ArchistarAPI.post('/map/getfilterlayerlist')
        .then(response => {
          if (response && Object.prototype.hasOwnProperty.call(response.data, 'filterLayers')) {
            const filterLayers = response.data.filterLayers;

            commit('SET_FILTER_LAYERS', filterLayers);
            commit('RESET_FILTER_LAYERS_TO_DEFAULT');
          }

          commit('SET_FILTER_LAYERS_LOADING', false);
        })
        .catch(() => {
          commit('SET_FILTER_LAYERS_LOADING', false);
        });
    }
  },
  mutations: {
    SET_FILTER_LAYERS_LOADING(state: FilterLayerState, payload: boolean): void {
      state.filterLayersLoading = payload;
    },
    SET_FILTER_LAYERS(state: FilterLayerState, payload: any): void {
      state.filterLayers = payload;
    },
    RESET_FILTER_LAYERS(state: FilterLayerState): void {
      state.filterLayers = [];
      state.filterStatuses = {};
    },
    TOGGLE_FILTER_LAYER_STATUS(
      state: FilterLayerState,
      payload: { filterLayerName: string; status: boolean | undefined }
    ) {
      if (typeof payload.status == 'undefined') payload.status = !state.filterStatuses[payload.filterLayerName];
      state.filterStatuses[payload.filterLayerName] = payload.status;
    },
    RESET_FILTER_LAYERS_TO_DEFAULT(state: FilterLayerState) {
      const newFilterStatuses = {};
      Object.keys(state.filterLayers).forEach(idxCat => {
        state.filterLayers[idxCat].filters.forEach(itFilt => {
          newFilterStatuses[itFilt.name] = itFilt.options.value;
        });
      });
      state.filterStatuses = newFilterStatuses;
    },
    TOGGLE_LAYER_LIST(state: FilterLayerState, bState: boolean | undefined): void {
      if (bState) state.showLayerList = bState;
      else state.showLayerList = !state.showLayerList;
    }
  },
  getters: {}
};
