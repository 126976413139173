
















import Vue from 'vue';
import BaseSiteDetails from '@/components/BaseSiteDetails.vue';
import AssessmentSlider from '@/components/AssessmentSlider.vue';
import { Site } from '@/types/Site';
import { SiteAssessmentValue } from '@/types/SiteAssessment';
import SiteHelpers from '@/services/siteHelpers';
import { Pipeline } from '@/types/Pipeline';
import { PipelineConfigAssessment } from '@/types/PipelineConfigAssessment';

export default Vue.extend({
  components: {
    BaseSiteDetails,
    AssessmentSlider
  },
  props: {
    siteGuid: {
      type: String,
      required: true
    }
  },
  computed: {
    site(): Site | undefined {
      return this.getSite(this.siteGuid);
    },
    activePipeline(): Pipeline | null {
      return this.$store.direct.state.Pipeline.activePipeline;
    },
    activeAssessmentId(): number | null {
      return this.$store.direct.state.Pipeline.activeAssessmentId;
    },
    siteAssessmentValue(): SiteAssessmentValue {
      if (!this.activeAssessmentId || this.site === undefined) {
        return 'unknown';
      }
      return SiteHelpers.getSiteAssessmentValue(this.site, this.activeAssessmentId);
    },
    activeAssessment(): PipelineConfigAssessment | null {
      return this.$store.direct.getters.Pipeline.getActiveAssessment;
    }
  },
  methods: {
    getSite(siteGuid: string): Site | undefined {
      return this.$store.direct.getters.Site.getSite(siteGuid);
    },
    async updateSiteAssessmentValue(value: string) {
      if (!this.activeAssessmentId || !this.activePipeline || this.site === undefined) {
        return;
      }
      this.$store.direct.dispatch.Site.updateSiteAssessmentValue({
        value,
        siteGuid: this.site.details.guid,
        assessmentId: this.activeAssessmentId,
        pipelineId: this.activePipeline.id
      });
    },
    close() {
      this.$emit('close');
    }
  }
});
