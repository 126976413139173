

























































import Vue, { PropType } from 'vue';
import _ from 'lodash';
import { PipelineConfigAssessment } from '@/types/PipelineConfigAssessment';
import { SiteAssessmentValue } from '@/types/SiteAssessment';
import AssessmentValueDropdown from '@/components/AssessmentValueDropdown.vue';
import SiteDetailsApi from '@/api/SiteDetailsApi';
import AssessmentLayerFilterMap from '@/config/assessmentLayerFilterMap';

export default Vue.extend({
  components: {
    AssessmentValueDropdown
  },
  data: () => ({
    overlays: [] as any
  }),
  props: {
    siteAssessmentValue: {
      type: String as PropType<SiteAssessmentValue>,
      required: true
    },
    isSitesPlural: {
      type: Boolean,
      default: false
    },
    siteGuid: {
      type: String,
      required: false
    }
  },
  async mounted() {
    if (this.siteGuid) {
      const response = await SiteDetailsApi.show(this.siteGuid);
      this.overlays = response?.data.data;
    }
  },
  computed: {
    activeAssessmentId(): number | null {
      return this.$store.direct.state.Pipeline.activeAssessmentId;
    },
    enabledAssessments(): readonly PipelineConfigAssessment[] {
      return this.$store.direct.getters.Pipeline.getEnabledAssessments;
    },
    activeAssessment(): PipelineConfigAssessment | null {
      return this.$store.direct.getters.Pipeline.getActiveAssessment;
    },
    previousAssessment(): PipelineConfigAssessment | null {
      if (_.isEmpty(this.enabledAssessments)) {
        return null;
      }
      if (!this.activeAssessmentId) {
        return _.last(this.enabledAssessments);
      }
      const activeAssessmentIndex = _.findIndex(this.enabledAssessments, { id: this.activeAssessmentId });
      return this.enabledAssessments[
        (activeAssessmentIndex - 1 + this.enabledAssessments.length) % this.enabledAssessments.length
      ];
    },
    nextAssessment(): PipelineConfigAssessment | null {
      if (_.isEmpty(this.enabledAssessments)) {
        return null;
      }
      if (!this.activeAssessmentId) {
        return this.enabledAssessments[0];
      }
      const activeAssessmentIndex = _.findIndex(this.enabledAssessments, { id: this.activeAssessmentId });
      return this.enabledAssessments[(activeAssessmentIndex + 1) % this.enabledAssessments.length];
    }
  },
  methods: {
    activateAssessment(assessmentId: number) {
      this.$store.direct.dispatch.Pipeline.setActiveAssessment(assessmentId);
    },

    getAssessmentOverlay(): string {
      if (!this.activeAssessment) {
        return '';
      }
      if (!AssessmentLayerFilterMap[this.activeAssessment.name]) return '';
      if (!this.overlays['overlays']) {
        return '';
      }
      let overlayText = '';
      AssessmentLayerFilterMap[this.activeAssessment.name].filterLayers.forEach(filterLayer => {
        if (this.overlays['overlays'][filterLayer.replace('overlay_', '')]) {
          overlayText += this.overlays['overlays'][filterLayer.replace('overlay_', '')];
        }
      });
      return overlayText;
    }
  }
});
