<template>
  <div>
    <label v-if="$attrs['label']" :for="$attrs['id']" class="block font-medium leading-5 text-grey-500 mb-2">{{
      $attrs['label']
    }}</label>
    <div
      class="editor rounded-lg border"
      :class="{
        'border-grey-200': !isFocused,
        'border-grey-400': isFocused
      }"
    >
      <div>
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
          <div v-show="menu === 'editor'" class="menubar bg-grey-300 p-2">
            <div class="grid grid-cols-12 gap-1">
              <!--              <div class="col-span-2">-->
              <!--                <ai-menu offset-y>-->
              <!--                  <template v-slot:activator="{ on, attrs }">-->
              <!--                    <ai-btn color="primary" dark v-bind="attrs" v-on="on">-->
              <!--                      Headings-->
              <!--                    </ai-btn>-->
              <!--                  </template>-->
              <!--                  <ai-list>-->
              <!--                    <ai-list-item>-->
              <!--                      <ai-list-item-title class="p-2">Heading 01</ai-list-item-title>-->
              <!--                    </ai-list-item>-->
              <!--                  </ai-list>-->
              <!--                </ai-menu>-->

              <!--                <select class="bg-grey-300 w-full p-2">-->
              <!--                  <option>Heading 1</option>-->
              <!--                  <option>2</option>-->
              <!--                  <option>3</option>-->
              <!--                  <option>4</option>-->
              <!--                  <option>5</option>-->
              <!--                </select>-->
              <!--              </div>-->
              <ai-btn :class="{ 'is-active': isActive.bold() }" @click="commands.bold" small color="secondary">
                <span class="">
                  <ai-icon-svg class="" icon="text-bold" width="1.25rem" height="1.25rem"></ai-icon-svg
                ></span>
              </ai-btn>

              <ai-btn
                class="grid-cols-1"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
                small
                color="secondary"
              >
                <span class="">
                  <ai-icon-svg class="" icon="text-italic" width="1.25rem" height="1.25rem"></ai-icon-svg
                ></span>
              </ai-btn>

              <ai-btn
                class="grid-cols-1"
                :class="{ 'is-active': isActive.underline() }"
                @click="commands.underline"
                small
                color="secondary"
              >
                <span class="">
                  <ai-icon-svg class="" icon="text-underline" width="1.25rem" height="1.25rem"></ai-icon-svg
                ></span>
              </ai-btn>

              <!--              <ai-btn-->
              <!--                class="grid-cols-1"-->
              <!--                :class="{ 'is-active': isActive.bold() }"-->
              <!--                @click="commands.bold"-->
              <!--                small-->
              <!--                color="secondary"-->
              <!--              >-->
              <!--                <span class="">-->
              <!--                  <ai-icon-svg class="" icon="text-align-left" width="1.25rem" height="1.25rem"></ai-icon-svg-->
              <!--                ></span>-->
              <!--              </ai-btn>-->

              <!--              <ai-btn-->
              <!--                class="grid-cols-1"-->
              <!--                :class="{ 'is-active': isActive.bold() }"-->
              <!--                @click="commands.bold"-->
              <!--                small-->
              <!--                color="secondary"-->
              <!--              >-->
              <!--                <span class="">-->
              <!--                  <ai-icon-svg class="" icon="text-align-center" width="1.25rem" height="1.25rem"></ai-icon-svg-->
              <!--                ></span>-->
              <!--              </ai-btn>-->

              <!--              <ai-btn-->
              <!--                class="grid-cols-1"-->
              <!--                :class="{ 'is-active': isActive.bold() }"-->
              <!--                @click="commands.bold"-->
              <!--                small-->
              <!--                color="secondary"-->
              <!--              >-->
              <!--                <span class="">-->
              <!--                  <ai-icon-svg class="" icon="text-align-right" width="1.25rem" height="1.25rem"></ai-icon-svg-->
              <!--                ></span>-->
              <!--              </ai-btn>-->

              <!--              <ai-btn-->
              <!--                class="grid-cols-1"-->
              <!--                :class="{ 'is-active': isActive.bold() }"-->
              <!--                @click="commands.bold"-->
              <!--                small-->
              <!--                color="secondary"-->
              <!--              >-->
              <!--                <span class="">-->
              <!--                  <ai-icon-svg class="" icon="text-align-justify" width="1.25rem" height="1.25rem"></ai-icon-svg-->
              <!--                ></span>-->
              <!--              </ai-btn>-->

              <!--              OLD STUFF - to be removed-->

              <!--            <ai-btn-->
              <!--                class="border border-grey-500 "-->
              <!--                :class="{ 'is-active': isActive.bold() }"-->
              <!--                @click="commands.bold"-->
              <!--                small-->
              <!--            >-->
              <!--              <ai-icon>mdi-format-bold</ai-icon>-->
              <!--            </ai-btn>-->

              <!--            <ai-btn-->
              <!--                class="border border-grey-500 "-->
              <!--                :class="{ 'is-active': isActive.bold() }"-->
              <!--                @click="commands.bold"-->
              <!--                small-->
              <!--            >-->
              <!--              <span class="">-->
              <!--                <ai-icon-svg class="" icon="text-bold" width="1.25rem" height="1.25rem"></ai-icon-svg-->
              <!--                ></span>-->
              <!--            </ai-btn>-->

              <!--            <ai-btn-->
              <!--              class="menubar__button"-->
              <!--              :class="{ 'is-active': isActive.bullet_list() }"-->
              <!--              @click="commands.bullet_list"-->
              <!--              small-->
              <!--            >-->
              <!--              <span class="">-->
              <!--                <ai-icon-svg class="" icon="text-align-justify" width="1.25rem" height="1.25rem"></ai-icon-svg-->
              <!--              ></span>-->
              <!--            </ai-btn>-->
              <!--            <ai-btn-->
              <!--              class="menubar__button"-->
              <!--              :class="{ 'is-active': isActive.ordered_list() }"-->
              <!--              @click="commands.ordered_list"-->
              <!--              small-->
              <!--            >-->
              <!--              <span class="">-->
              <!--                <ai-icon-svg class="" icon="text-align-justify" width="1.25rem" height="1.25rem"></ai-icon-svg-->
              <!--              ></span>-->
              <!--            </ai-btn>-->

              <!--            <ai-btn class="" :class="{ 'is-active': isActive.italic() }" @click="commands.italic" small>-->
              <!--              <ai-icon>mdi-format-italic</ai-icon>-->
              <!--            </ai-btn>-->
            </div>
          </div>
        </editor-menu-bar>
      </div>

      <editor-content class="editor__content prose" :style="editorStyles" :editor="editor" />

      <div v-if="menu === 'basic'" style="min-height: 44px">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, focused }">
          <transition
            enter-active-class="transition ease-out duration-500 transform"
            enter-class="opacity-0 translate-y-4"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200 transform"
            leave-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-4"
          >
            <div v-show="focused" class="menubar rounded-b-lg py-1 bg-grey-50">
              <ai-btn
                class="menubar__button"
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
                icon
                text
              >
                <ai-icon>mdi-format-bold</ai-icon>
              </ai-btn>
              <ai-btn
                class="menubar__button"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
                icon
                text
              >
                <ai-icon>mdi-format-italic</ai-icon>
              </ai-btn>
              <ai-btn
                class="menubar__button"
                :class="{ 'is-active': isActive.bullet_list() }"
                @click="commands.bullet_list"
                icon
                text
              >
                <ai-icon>mdi-format-list-bulleted</ai-icon>
              </ai-btn>
              <ai-btn
                class="menubar__button"
                :class="{ 'is-active': isActive.ordered_list() }"
                @click="commands.ordered_list"
                icon
                text
              >
                <ai-icon>mdi-format-list-numbered</ai-icon>
              </ai-btn>
            </div>
          </transition>
        </editor-menu-bar>
      </div>
    </div>
  </div>
</template>

<script>
/*
 * Note: Tip tap is only adding Typescript support in v2. Hence leaving typescript out for now for this component.
 */
import Vue from 'vue';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import { OrderedList, BulletList, ListItem, Bold, Link, Strike, Underline, Italic } from 'tiptap-extensions';

export default Vue.extend({
  components: {
    EditorContent,
    EditorMenuBar
  },
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    defaultWith: {
      type: String,
      required: false,
      default: ''
    },
    menu: {
      type: String,
      required: false,
      default: 'none'
    }
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Bold(),
          new Italic(),
          new BulletList(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline()
        ],
        content: this.value,
        onBlur: () => {
          this.$emit('input', this.editor.getHTML());
          this.isFocused = false;
        },
        onFocus: () => {
          this.isFocused = true;
          this.$emit('focus');
        }
      }),
      hasEditorChange: false,
      isFocused: false
    };
  },
  watch: {
    value(val) {
      if (this.hasEditorChange) {
        this.hasEditorChange = false;
        return;
      }
      if (this.editor) {
        this.editor.setContent(val);
      }
    }
  },
  mounted() {
    // Hook in to add sensible Tailwind typography styling
    this.editor.view.dom.classList.add('prose');
  },
  computed: {
    editorStyles() {
      return 'bg-grey-200';
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
});
</script>

<style lang="scss" scoped>
::v-deep .ProseMirror {
  color: rgba(0, 0, 0, 0.87);
  @apply p-2;
  &:focus {
    @apply outline-none;
  }

  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  ul > li > *:last-child,
  ol > li > *:last-child {
    @apply my-1;
  }

  min-height: 6.5rem;

  p {
    margin: 0;
  }
}

::v-deep .menubar {
  .menubar__button.is-active {
    @apply bg-grey-400;
  }

  .editor-btn {
    background-color: transparent;
    border: black;
  }
}
</style>
