








































































































































































































































































import Vue from 'vue';
export default Vue.extend({
  components: {},
  props: {},
  computed: {
    transferAppUrl(): string {
      return `${process.env.VUE_APP_URL_ARCHISTAR}`;
    }
  }
});
