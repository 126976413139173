






import Vue from 'vue';
import BaseNotification from '@/components/BaseNotification.vue';
import { FilterLayerStatus } from '@/types/FilterLayerStatus';
import _ from 'lodash';
import { FilterLayer } from '@/types/FilterLayer';

export default Vue.extend({
  components: { BaseNotification },
  data: () => ({
    notificationMessage: '',
    closeNotificationTimer: undefined as number | undefined
  }),
  computed: {
    filtersOn(): FilterLayerStatus {
      return _.pickBy(this.filterStatuses, filterStatus => {
        return filterStatus === true;
      });
    },
    filterLayers(): FilterLayer[] {
      return this.$store.direct.state.FilterLayer.filterLayers;
    },
    filterStatuses(): FilterLayerStatus {
      return this.$store.direct.state.FilterLayer.filterStatuses;
    }
  },
  watch: {
    filterStatuses: {
      handler(newValue, oldValue) {
        this.clearNotificationMessage();
        clearTimeout(this.closeNotificationTimer);
        const filtersTurnedOn = _.pickBy(this.filtersOn, (status, filterStatusId) => {
          return oldValue[filterStatusId] === false;
        });
        if (_.isEmpty(filtersTurnedOn)) {
          return;
        }
        const filterName = _.find(_.flatMap(this.filterLayers, 'filters'), filter => {
          return _.includes(filter.name, Object.keys(filtersTurnedOn)[0]);
        });
        if (!filterName) {
          return;
        }
        this.notificationMessage = `${filterName.options.label} layer turned on`;

        this.closeNotificationTimer = setTimeout(() => {
          this.clearNotificationMessage();
        }, 3000);
      }
    }
  },
  methods: {
    clearNotificationMessage() {
      this.notificationMessage = '';
    }
  }
});
