




























































































































































































































































import Mapbox from '@/components/Mapbox.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import { Multipane, MultipaneResizer } from 'vue-multipane';
import MapLayerToggles from '@/components/MapLayerToggles.vue';
import MapSearch from '@/components/MapSearch.vue';
import PopupMultipleSelectedSite from '@/components/PopupMultipleSelectedSite.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import Map from '@/services/map';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import Vue, { VueConstructor } from 'vue';
import PipelineApi from '@/api/PipelineApi';
import { Stage } from '@/types/Stage';
import { Pipeline } from '@/types/Pipeline';
import { Site } from '@/types/Site';
import BaseBtn from '@/components/BaseBtn.vue';
import BaseNotification from '@/components/BaseNotification.vue';
import PipelineSiteApi from '@/api/PipelineSiteApi';
import _ from 'lodash';
import { extend } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';
//import { APIHelper } from '@archistarai/auth-frontend';

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
});
extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
});

const STATE = Object.freeze({
  INIT: 'init',
  LOADED: 'loaded',
  CLEARING: 'clearing',
  DELETING: 'deleting',
  RENAMING: 'renaming'
});

type VueExt = Vue & {
  $refs: {
    mapPane: InstanceType<typeof HTMLInputElement>;
  };
};

export default (Vue as VueConstructor<VueExt>).extend({
  components: {
    BaseBtn,
    BaseMenu,
    BaseDialog,
    BaseNotification,
    MapLayerToggles,
    MapSearch,
    Mapbox,
    Multipane,
    MultipaneResizer,
    PopupMultipleSelectedSite,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    STATE,
    state: STATE.INIT as string,
    pipelines: [] as Pipeline[],
    mapPaneHeight: 0 as number,
    isPipelineActionsOpen: false as boolean,
    pipelineRenameText: '' as string,
    pipelineNewName: '' as string,
    isRenameSaving: false as boolean,
    isAlertsSaving: false as boolean,
    isCreateSaving: false as boolean,
    isShownDialogCreatePipeline: false as boolean
  }),
  computed: {
    pipelineAlertsOn(): boolean {
      return !!(this.activePipeline && this.activePipeline.alertSub);
    },
    getStages(): readonly Stage[] {
      return this.$store.direct.getters.Pipeline.getStages;
    },
    sites(): { [siteGuid: string]: Site } {
      return this.$store.direct.state.Site.sites;
    },
    activePipeline(): Pipeline | null {
      return this.$store.direct.state.Pipeline.activePipeline;
    },
    activeStageId(): number | null {
      return this.$store.direct.state.Pipeline.activeStageId;
    },
    displayedPipelines(): Pipeline[] {
      if (!this.activePipeline) {
        return [];
      }
      return _.reject(this.pipelines, { id: this.activePipeline.id });
    },
    shouldClearInsteadOfDelete(): boolean {
      return this.pipelines.length <= 1;
    }
  },
  watch: {
    '$route.params.pipelineId': {
      handler() {
        this.state = STATE.INIT;
        this.activate();
      }
    }
  },
  async mounted() {
    await this.activate();
    this.$nextTick(() => {
      if (this.$refs.mapPane) {
        this.setMapPaneHeight(this.$refs.mapPane.offsetHeight);
      }
    });
  },
  methods: {
    resetSiteStoreState() {
      this.$store.direct.dispatch.Site.reset();
    },
    async activate() {
      if (this.$route.name === 'PipelineShow') {
        await this.$router.replace({
          name: 'PipelineStageShow',
          params: { stageId: '1' }
        });
      }
      let response = await PipelineApi.show(parseInt(this.$route.params.pipelineId));
      if (response && response.data) {
        this.setActivePipeline(response?.data.data);
        response = await PipelineApi.index();
        this.pipelines = response.data.data;
        this.state = STATE.LOADED;
      } else {
        if (response === 403) {
          await this.$router.push('/error/403');
        }
      }
    },
    setActivePipeline(payload: Pipeline) {
      this.$store.direct.dispatch.Pipeline.setActivePipeline(payload);
    },
    setMapPaneHeight(payload: number) {
      this.$store.direct.dispatch.Layout.setMapPaneHeight(payload);
    },
    getConfigDetailStage(stageId: number) {
      return this.$store.direct.getters.Pipeline.getConfigDetailStage(stageId);
    },
    resizeMap() {
      Map.resizeMap();
    },
    paneResize(pane, container, size) {
      this.setMapPaneHeight(parseInt(size.replace('px', '')));
    },
    getSiteCountForStage(stageId: number): number {
      if (!this.activePipeline) {
        return 0;
      }
      return this.getConfigDetailStage(stageId)?.siteCount;
    },
    clearActiveSites() {
      return this.$store.direct.dispatch.Site.clearActiveSites();
    },
    clearSelectedSites() {
      return this.$store.direct.dispatch.Site.clearSelectedSites();
    },
    async confirmClearOrDeletePipeline() {
      const message = 'Your sites and assessments will be deleted. This cannot be undone.';
      if (
        await this.$root.$confirm(
          `Are you sure you want to ${this.shouldClearInsteadOfDelete ? 'clear' : 'delete'} ${
            this.activePipeline?.name
          }?`,
          message,
          `${this.shouldClearInsteadOfDelete ? 'Clear' : 'Delete'} pipeline`
        )
      ) {
        try {
          if (this.shouldClearInsteadOfDelete) {
            await this.clearPipeline();
          } else {
            await this.deletePipeline();
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async clearPipeline() {
      this.state = STATE.CLEARING;
      try {
        await PipelineSiteApi.destroy(parseInt(this.$route.params.pipelineId));
        await this.activate();
        this.resetSiteStoreState();
      } finally {
        this.state = STATE.LOADED;
        this.isPipelineActionsOpen = false;
      }
    },
    async deletePipeline() {
      this.state = STATE.DELETING;
      try {
        await PipelineApi.destroy(parseInt(this.$route.params.pipelineId));
        await this.$router.push({
          name: 'PipelineShow',
          params: { pipelineId: _.last(this.displayedPipelines).id }
        });
      } finally {
        this.state = STATE.LOADED;
        this.isPipelineActionsOpen = false;
      }
    },
    enterRenameState() {
      this.$nextTick(() => {
        setTimeout(() => {
          const $input = this.$refs.inputRenamePipeline as Vue;
          if ($input) {
            $input.$el.querySelector('input')?.focus();
          }
        });
      });
      this.pipelineRenameText = this.activePipeline ? this.activePipeline.name : '';
      this.state = STATE.RENAMING;
    },
    leaveRenameState() {
      this.state = STATE.LOADED;
    },
    showCreateNewDialog() {
      this.pipelineNewName = '';
      this.isShownDialogCreatePipeline = true;
      (this.$refs.newPipelineObserver as InstanceType<typeof ValidationObserver>).reset();
      this.$nextTick(() => {
        setTimeout(() => {
          const $input = this.$refs.inputNewPipeline as Vue;
          if ($input) {
            $input.$el.querySelector('input')?.focus();
          }
        });
      });
    },
    async renamePipeline() {
      if (!this.activePipeline) {
        return;
      }
      this.isRenameSaving = true;
      try {
        await PipelineApi.update({ id: this.activePipeline.id, name: this.pipelineRenameText });
        await this.activate();
        this.state = STATE.LOADED;
      } catch (e) {
        console.error('Could not rename pipeline');
      } finally {
        this.isRenameSaving = false;
      }
    },
    async createNewPipeline() {
      this.isCreateSaving = true;
      try {
        const response = await PipelineApi.store({ name: this.pipelineNewName });
        this.isShownDialogCreatePipeline = false;
        await this.$router.push({
          name: 'PipelineShow',
          params: { pipelineId: response.data.data.id }
        });
      } catch (e) {
        console.error('Could not create pipeline');
      } finally {
        this.isCreateSaving = false;
      }
    },
    async subscribeAlerts() {
      if (!this.activePipeline) {
        return;
      }
      this.isAlertsSaving = true;
      try {
        if (this.activePipeline.alertSub) {
          await PipelineApi.unsubscribeAlerts(this.activePipeline.id);
        } else {
          await PipelineApi.subscribeAlerts(this.activePipeline.id);
        }
        await this.activate();
        this.state = STATE.LOADED;
      } catch (e) {
        console.error('Could not update alerts');
      } finally {
        this.isAlertsSaving = false;
      }
    }
  }
});
