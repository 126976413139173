import { APIHelper } from '@archistarai/auth-frontend';
import { PipelineConfigAssessment } from '@/types/PipelineConfigAssessment';

export default {
  async show(pipelineId: number) {
    return APIHelper.ManageAPI.get(`assessments/pipeline/${pipelineId}`);
  },
  async update(pipelineId: number, assessments: PipelineConfigAssessment) {
    return APIHelper.ManageAPI.put(`assessments/pipeline/${pipelineId}`, assessments);
  }
};
