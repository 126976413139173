

















































































































































































import Vue, { PropType } from 'vue';
import { SiteAssessmentValue } from '@/types/SiteAssessment';

export default Vue.extend({
  props: {
    value: {
      type: String as PropType<SiteAssessmentValue>,
      default: 'unknown'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    activated: false,
    isOpen: false
  }),
  methods: {
    inputUpdated(value) {
      this.isOpen = value;
    },
    select(value) {
      this.$emit('input', value);
    }
  }
});
