import _ from 'lodash';
import { ActionContext } from 'vuex';
import SiteCardApi from '@/api/SiteCardApi';
import { SiteCard } from '@/types/SiteCard';
import { SiteCardNote } from '@/types/SiteCardNote';
import { SiteCardUpload } from '@/types/SiteCardUpload';
import { SiteCardOrderedDocument } from '@/types/SiteCardOrderedDocument';
import ImageCreator from '@/services/imageCreator';
import { SiteCardImageList } from '@/types/SiteCardImageList';
import { SiteInfo } from '@/types/SiteInfo';
import { Site } from '@/types/Site';
import SiteApi from '@/api/SiteApi';

interface SiteCardState {
  activeSiteCard: SiteCard | null;
  activeGroups: any;
  allUploads: SiteCardUpload[];
  loadingImages: boolean;
  siteObject: SiteInfo[];
}

export default {
  namespaced: true as const,
  state: {
    activeSiteCard: null,
    activeGroups: ['all'],
    allUploads: [],
    siteObject: [],
    loadingImages: false
  } as SiteCardState,
  actions: {
    async getSiteCardOrCreate(
      { commit, dispatch }: ActionContext<SiteCardState, any>,
      activeSiteId: number
    ): Promise<void> {
      commit('SET_SITE_CARD', null);
      const response = await SiteCardApi.getOrNew(activeSiteId);
      commit('SET_SITE_CARD', response?.data.data);
      await dispatch('getAttachments');
    },
    clearSiteCard({ commit }: ActionContext<SiteCardState, any>) {
      commit('SET_SITE_CARD', null);
    },
    async saveCard({ state, commit }: ActionContext<SiteCardState, any>): Promise<void> {
      if (state.activeSiteCard) {
        const response = await SiteCardApi.saveCard(state.activeSiteCard);
        commit('SET_SITE_CARD', response?.data.data);
      }
    },
    async getAttachments({ state, commit }: ActionContext<SiteCardState, any>): Promise<void> {
      if (state.activeSiteCard) {
        const response = await SiteCardApi.getAttachmentList(state.activeSiteCard);
        commit('SET_SITE_CARD_UPLOAD', response?.data.data);
      }
    },
    async saveCardWithNoChangeToState({ state }: ActionContext<SiteCardState, any>): Promise<void> {
      if (state.activeSiteCard) {
        await SiteCardApi.saveCard(state.activeSiteCard);
      }
    },
    //Sections
    addSection({ state, commit, dispatch }: ActionContext<SiteCardState, any>, type: string) {
      if (type.startsWith('layer:')) {
        const overlay = type.split(':').pop();
        if (overlay && (!state.activeSiteCard?.images || !state.activeSiteCard?.images[overlay])) {
          dispatch('addImage', overlay);
        }
      }
      commit('ADD_SECTION', type);
    },
    updateSection({ commit }: ActionContext<SiteCardState, any>, type: string) {
      commit('ADD_SECTION', type);
    },
    async removeSection({ state, dispatch }: ActionContext<SiteCardState, any>, index: number) {
      if (state.activeSiteCard) {
        state.activeSiteCard.sections.splice(index, 1);
      }
      await dispatch('saveCardWithNoChangeToState');
    },
    //Notes
    async addNote({ commit, dispatch }: ActionContext<SiteCardState, any>, note: SiteCardNote) {
      commit('ADD_NOTE', note);
      await dispatch('saveCardWithNoChangeToState');
    },
    async removeNote({ state, dispatch }: ActionContext<SiteCardState, any>, index: number) {
      if (state.activeSiteCard) {
        state.activeSiteCard.notes.splice(index, 1);
      }
      await dispatch('saveCardWithNoChangeToState');
    },
    //Site Info - Images
    async addImage({ state, commit }: ActionContext<SiteCardState, any>, overlay: string) {
      commit('UPDATE_LOADING_IMAGES', true);
      const imgResp = await ImageCreator.createPropertyOverlayImageURL(
        state.activeSiteCard?.site.details.guid,
        overlay
      );
      commit('UPDATE_SITE_IMAGE', {
        overlay,
        imgRespData: imgResp.data
      });
      commit('UPDATE_LOADING_IMAGES', false);
    },
    async setSiteInfoLayer({ state, commit }: ActionContext<SiteCardState, any>, name: string): Promise<void> {
      if (state.activeSiteCard) {
        const response = await SiteApi.setInfoLayer(state.activeSiteCard.site.id, name);
        commit('SET_SITE_CARD_SITE', response?.data.data);
      }
    },
    async setSiteInfoImage({ state, commit }: ActionContext<SiteCardState, any>, name: string): Promise<void> {
      if (state.activeSiteCard) {
        const response = await SiteApi.setInfoImage(state.activeSiteCard.site.id, name);
        commit('SET_SITE_CARD_SITE', response?.data.data);
      }
    }
  },
  mutations: {
    SET_SITE_CARD: (state: SiteCardState, siteCard: SiteCard): void => {
      state.activeSiteCard = siteCard;
    },
    SET_SITE_CARD_SITE: (state: SiteCardState, site: Site): void => {
      if (state.activeSiteCard) {
        state.activeSiteCard.site = site;
      }
    },
    SET_SITE_CARD_UPLOAD: (state: SiteCardState, siteCardUpload: SiteCardUpload[]): void => {
      state.allUploads = siteCardUpload;
    },
    ADD_ORDERED_DOCUMENT: (state: SiteCardState, type: string): void => {
      state.activeSiteCard?.documents.push({
        url: '',
        type: type
      });
    },
    ADD_NOTE: (state: SiteCardState, note: SiteCardNote): void => {
      if (state.activeSiteCard && !state.activeSiteCard.notes) {
        state.activeSiteCard.notes = [];
      }
      state.activeSiteCard?.notes.push(note);
    },
    TOGGLE_SHOW_GROUP: (state: SiteCardState, group: string): void => {
      if (group === 'all') {
        state.activeGroups = ['all'];
        return;
      }
      const existsIndex = _.indexOf(state.activeGroups, group);
      if (existsIndex > -1) {
        state.activeGroups.splice(existsIndex, 1);
      } else {
        state.activeGroups.push(group);
      }
      //if the group being added it not all then remove all
      if (group !== 'all') {
        const allIndex = _.indexOf(state.activeGroups, 'all');
        if (allIndex > -1) {
          state.activeGroups.splice(allIndex, 1);
        }
      }
      if (state.activeGroups.length < 1) {
        state.activeGroups.push('all');
      }
    },
    ADD_SECTION: (
      state: SiteCardState,
      type: string
    ): { sequence: number; page: number; type: string; colSize: number; content: string; group: string } => {
      let colSize = 3;
      let group = '';
      let content = '';
      //All : all Text sections : texts | Notes :  notes| Images : images | Documents : documents |  Permitted use : uses |  Reports : reports
      //Land views : layers | Feasibility : feaso | Design options : design |  Suburb insights : insights | Development activity :dev-activity
      switch (type) {
        case 'note':
          content = '';
          colSize = 3;
          group = 'texts';
          break;
        case 'image':
          content = 'url';
          colSize = 3;
          group = 'images';
          break;
        case 'editor':
          content = '';
          colSize = 3;
          group = 'texts';
          break;
        case 'layer:bushfire':
        case 'layer:flood':
        case 'layer:contours':
        case 'layer:zoning':
        case 'layer:heritage':
          content = '';
          colSize = 3;
          group = 'layers';
          break;
        case 'feasibility':
          content = 'Im a new feasibility';
          colSize = 3;
          group = 'feasibility';
          break;
        case 'permittedUses':
          content = 'Im a new permittedUses';
          colSize = 3;
          group = 'permittedUses';
          break;
      }
      const newSection = {
        page: 1,
        group: group,
        type: type,
        colSize: colSize,
        content: content,
        sequence: 1
      };
      if (state.activeSiteCard) {
        state.activeSiteCard.sections.push(newSection);
      }

      return newSection;
    },
    UPDATE_SITE_IMAGE: (state: SiteCardState, payload: { overlay: string; imgRespData: any }): void => {
      if (state.activeSiteCard) {
        if (!state.activeSiteCard.images) state.activeSiteCard.images = {} as SiteCardImageList[];
        state.activeSiteCard.images[payload.overlay] = payload.imgRespData;
      }
    },
    UPDATE_LOADING_IMAGES: (state: SiteCardState, newState: boolean): void => {
      state.loadingImages = newState;
    }
  },
  getters: {
    getNotes: (state: SiteCardState): SiteCardNote[] => {
      if (!state.activeSiteCard) {
        return [];
      }
      return state.activeSiteCard.notes;
    },
    getOrderedDocuments: (state: SiteCardState): SiteCardOrderedDocument[] => {
      if (!state.activeSiteCard) {
        return [];
      }
      return state.activeSiteCard.documents;
    },
    getUploads: (state: SiteCardState): SiteCardUpload[] => {
      if (!state.activeSiteCard) {
        return [];
      }
      return state.allUploads;
    },
    getDateString() {
      const d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      return day + '.' + month + '.' + year;
    },
    getSiteInfo: (state: SiteCardState): SiteInfo | null => {
      if (state.activeSiteCard && state.activeSiteCard.site) {
        return state.activeSiteCard.site.info;
      }
      return null;
    }
  }
};
