













































































































































import Vue from 'vue';
import BaseBtn from '@/components/BaseBtn.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import { Site } from '@/types/Site';
import SiteHelpers from '@/services/siteHelpers';
import AssessmentValueDropdown from '@/components/AssessmentValueDropdown.vue';
import { SiteAssessmentValue } from '@/types/SiteAssessment';
import { PipelineConfigAssessment } from '@/types/PipelineConfigAssessment';
import AddSites from '@/components/ImportSite/AddSites.vue';
import { Pipeline } from '@/types/Pipeline';
import SiteList from '@/components/SiteList.vue';
import SiteOpportunitiesNote from '@/components/SiteOpportunitiesNote.vue';
// import FilterOpportunities from '@/components/FilterOpportunities.vue'; // Temporary hide, plz don't remove
import AssessmentsConfiguration from '@/components/AssessmentsConfiguration.vue';
import _ from 'lodash';

export default Vue.extend({
  components: {
    AssessmentsConfiguration,
    SiteOpportunitiesNote,
    SiteList,
    AddSites,
    BaseBtn,
    AssessmentValueDropdown,
    BaseMenu
  },
  props: {
    type: {
      type: String,
      default: 'export'
    }
  },
  data: () => ({
    isShownDialogPipelineSites: false as boolean,
    isShownDialogAssessmentsConfig: false as boolean,
    showSiteFilter: false as boolean,
    stagedSiteGuids: [] as string[],
    siteListKey: 1 as number,
    siteListDefaultSort: ''
  }),
  computed: {
    activePipeline(): Pipeline | null {
      return this.$store.direct.state.Pipeline.activePipeline;
    },
    activeStageId(): number | null {
      return this.$store.direct.state.Pipeline.activeStageId;
    },
    sites(): { [siteGuid: string]: Site } {
      return this.$store.direct.state.Site.sites;
    },
    activeAssessmentId(): number | null {
      return this.$store.direct.state.Pipeline.activeAssessmentId;
    },
    assessments(): readonly PipelineConfigAssessment[] {
      return this.$store.direct.getters.Pipeline.getAssessments;
    },
    selectedSiteGuids(): string[] {
      return _.reject(this.$store.direct.state.Site.selectedSiteGuids, _.isEmpty);
    }
  },
  methods: {
    showFilter() {
      this.showSiteFilter = true;
    },
    getSiteAssessmentValue(site: Site, assessmentId: number): SiteAssessmentValue {
      return SiteHelpers.getSiteAssessmentValue(site, assessmentId);
    },
    async updateSiteAssessmentValue(value: string, site: Site, assessmentId: number) {
      this.$store.direct.dispatch.Site.updateSiteAssessmentValue({
        value,
        siteGuid: site.details.guid,
        assessmentId: assessmentId,
        pipelineId: parseInt(this.$route.params.pipelineId)
      });
    },
    toggleAssessment(assessmentId: number) {
      if (this.activeAssessmentId === assessmentId) {
        this.clearActiveAssessment();
        return;
      }
      this.$store.direct.dispatch.Pipeline.setActiveAssessment(assessmentId);
    },
    activateAssessment(assessmentId: number) {
      this.$store.direct.dispatch.Pipeline.setActiveAssessment(assessmentId);
    },
    clearActiveAssessment() {
      this.$store.direct.dispatch.Pipeline.clearActiveAssessment();
    },
    setSiteNote(note: string, siteGuid: string) {
      return this.$store.direct.dispatch.Site.setSiteNote({ siteGuid, note });
    },
    isSiteSelected(siteGuid: string): boolean {
      return this.$store.direct.getters.Site.isSiteSelected[siteGuid];
    },
    refreshSiteList(defaultSort = '') {
      this.siteListDefaultSort = defaultSort;
      this.siteListKey = this.siteListKey + 1;
    }
  }
});
